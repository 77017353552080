import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { useForm } from 'react-hook-form';
import { toast } from 'react-hot-toast';
import { useSendInternalCompleteMutation } from '../../../../../../store/slices/internalLogisticsSlice';
import { useSendCompleteMutation } from '../../../../../../store/slices/logisticsSlice';
import {
  defaultValues,
  schema
} from './schema';

export const useApprovedForm = ({ order, onClose, dashboardType }) => {
  const [sendCurrent, isLoadingCurrent] = useSendCompleteMutation();
  const [sendInternal, isLoadingInternal] = useSendInternalCompleteMutation();
  const send = dashboardType === 'orders' ? sendCurrent : sendInternal;
  const isLoading = dashboardType === 'orders' ? isLoadingCurrent : isLoadingInternal;
  const formMethods = useForm({
    mode: 'onChange',
    resolver: yupResolver(schema),
    defaultValues
  });

  const onSubmit = async (data) => {
    const toastId = toast.loading('Loading...');
    const sendData = dashboardType === 'orders' ? { ...data } : { 
      ttn_code: data?.ttm_code, 
      delivery_operator_id: data?.delivery_operator_id,
      ...(!!data?.delivery_due_date && { delivery_due_date: data?.delivery_due_date })
    };

    delete sendData?.isRequiredDueDate;
    
    const { error } = await send({ id: order.id, data: sendData });
 
    if (error) {
      toast.error(error.data.message || 'Something went wrong', {
        id: toastId
      });

      return;
    }

    onClose();
    toast.success('Successfully!', {
      id: toastId
    });
  };

  return {
    formMethods,
    defaultValues,
    handleSubmit: formMethods.handleSubmit,
    onSubmit,
    isLoading
  };
};
