import { Card, Grid } from '@mui/material';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-hot-toast';
import EditableTable from '../../../components/EditableTable';
import {
  useCopyFlightMutation,
  useDeleteFlightsMutation,
  useGetAircraftListQuery,
  useGetFlightsListQuery
} from '../../../store/session';
import { columns } from './columns';

const Table = (props) => {
  const [refetchTable, setRefetchTable] = useState(0);
  const { title, type, handleSetSortExport } = props;
  const { data: AircraftList } = useGetAircraftListQuery('?status=3');
  const [copyFlight] = useCopyFlightMutation();

  const aircraftOptions = AircraftList?.map((item) => ({
    value: item.id,
    label: item.aircraft_registration
  }));

  const handleRefetchTable = () => {
    setRefetchTable(prev => prev + 1);
  };

  const handleCopyFlights = (id) => {
    const res = copyFlight(id);

    toast.promise(res, {
      loading: 'Loading',
      success: 'Quotation deleted',
      error: 'Error when deleting',
    }).then((result) => {
      window.open(`${process.env.REACT_APP_URL}flights/${result.data.id}`);
      handleRefetchTable();
    });
  };

  useEffect(() => {
    if (aircraftOptions) {
      aircraftOptions?.push({ value: '', label: '-' });
    }
  }, [aircraftOptions]);

  return (
    <Grid container spacing={6}>
      <Grid item xs={12}>
        <Card>
          <Box sx={{
            p: 5, pb: 3, display: 'flex', flexWrap: 'wrap', alignItems: 'center', justifyContent: 'space-between',
          }}
          >
            <Typography variant="h3" noWrap sx={{ textTransform: 'capitalize' }}>
              {title}
            </Typography>

          </Box>
          <EditableTable
            getRowId={(row) => row.flight_id}
            useHook={useGetFlightsListQuery}
            useHookDelete={useDeleteFlightsMutation}
            tableParams={{
              type
            }}
            getRowHeight={() => 'auto'}
            sortByType="DESC"
            sortByActive="planing_index"
            aircraftOptions={aircraftOptions}
            handleCopyFlights={handleCopyFlights}
            columns={columns}
            refetchTable={refetchTable}
            setSortOut={({ field, sort: sortFieldEx }) => handleSetSortExport(field, sortFieldEx)}
          />

        </Card>
      </Grid>
    </Grid>
  );
};

export default Table;
