import { Box, styled } from '@mui/material';

export const ProgressBarContainer = styled(Box)(() => ({
  width: '100%', 
  marginRight: '4px', 
  height: '6px', 
  display: 'flex', 
  backgroundColor: '#e5e4e4a1', 
  borderRadius: '10px', 
  overflow: 'hidden', 
  position: 'relative',
}));

export const CustomProgressBarStyled = styled(Box)(() => ({
  display: 'flex', 
  alignItems: 'center', 
  width: '50%',
}));

export const ProgressBarBar = styled(Box)(() => ({
  backgroundColor: '#368600bf', 
  height: '100%',
}));