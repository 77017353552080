import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { Box, Card } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { toast } from 'react-hot-toast';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import Button from '../../../../components/Button';
import ContentPageLoader from '../../../../components/Loader/ContentPageLoader';
import {
  useCreateArtMaintenanceMutation,
  useDeleteRefFileMaintenanceMutation,
  useGetAircraftListQuery,
  useGetDetailMaintenanceMutation,
  useGetRefFileListMaintenanceMutation,
  useOpenRefFileMaintenanceMutation,
  useUpdateArtMaintenanceMutation,
  useUploadRefFileMaintenanceMutation
} from '../../../../store/session';
import { setFilesName } from '../../../../utils/constants/portalFiles';
import { useHandleQueryFormError } from '../../../../utils/hooks/useHandleQueryFormError';
import { usePermissions } from '../../../../utils/hooks/usePermissions';
import FileDrawer from '../../../TrainingModule/FileDrawer/FileDrawer';
import DueAtAc from './DueAtAc';
import General from './General';
import Interval from './Interval';
import LastDone from './LastDone';
import Remaining from './Remaining';
import { defaultValues, schema } from './schema';
import Status from './Status';
import {
  backIcon, cancelBtn, cardContainer, formContainer, headerSx, saveBtn
} from './style';

const AtrMaintenanceForm = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();
  const [fileDrawerState, setFileDrawerState] = useState({
    isOpen: false,
    type: null,
    fileList: [],
    name: '',
    currentName: '',
    currentId: null
  });
  const [nameFile, setNameFile] = useState(null);
  const [selectAircraft, setSelectAircraft] = useState([]);

  const handleError = useHandleQueryFormError();

  const hasPermissionsMaintenance = usePermissions('maintenance_edit');

  const isUpdate = location.pathname.split('/')[2] === 'update';

  const { data: aircraft } = useGetAircraftListQuery('?with_current_ods=true');
  const [getMaintenance, { isLoading: isLoadingMaintenance }] = useGetDetailMaintenanceMutation();
  const [create, { isLoading: isLoadingCreate }] = useCreateArtMaintenanceMutation();
  const [update, { isLoading: isLoadingUpdate }] = useUpdateArtMaintenanceMutation();
  const [updateFiles, { isLoading: isAttachLoading }] = useUploadRefFileMaintenanceMutation();
  const [getFiles, { isLoading: isGetFilesLoading }] = useGetRefFileListMaintenanceMutation();

  const methods = useForm({
    mode: 'onChange',
    defaultValues,
    resolver: yupResolver(schema)
  });

  const {
    handleSubmit, setValue, setError, getValues, watch, reset, formState: { dirtyFields }
  } = methods;

  const handleBack = () => {
    navigate('/maintenance/aircraft/1');
  };

  const handleUpdateValues = (result) => {
    Object.entries(result).forEach(
      ([name, value]) => {
        setValue(name, value);
      }
    );
  };

  const handleFlightData = async () => {
    const { data: result } = await getMaintenance(params.id);

    if (result) {
      handleUpdateValues(result);
    }
  };

  const uploadFile = (e) => {
    const countFiles = e.target.files.length;
    setNameFile(countFiles > 1 ? `${countFiles} files selected` : e.target.files[0].name);
    setValue('upload_files', e.target.files);
  };

  const onSubmit = async (data) => {
    const toastId = toast.loading('Loading...');

    if (!isUpdate) {
      const formData = new FormData();
      const disableKey = ['is_active', 'is_checked', 'use_interval'];
      Object.entries(data).forEach(([key, value]) => {
        if (typeof value !== 'undefined' && !disableKey.includes(value)) {
          if (key === 'upload_files') {
            Object.keys(value)?.forEach((valueKey) => {
              formData.append(`${key}[${valueKey}]`, value[valueKey]);
            });
          } else if (key === 'aircraft_ids') {
            value.forEach((vlv, index) => {
              formData.append(`${key}[${index}]`, vlv);
            });
          } else {
            formData.append(key, value);
          }
        }
      });
      
      formData.append('is_active', data.is_active ? 1 : 0);
      formData.append('is_checked', data.is_checked ? 1 : 0);
      formData.append('version', params.version);
      formData.append('use_interval', data.use_interval ? 1 : 0);
      const res = await create(formData);

      if (res.error) {
        handleError(res.error, setError, getValues, toastId);

        return;
      }
      
      toast.success('Success!', { id: toastId });

      if (res?.data && Array.isArray(res?.data)) {
        res?.data.forEach(vlv => {
          const curAircraft = (aircraft || [])?.find(air => air.id.toString() === vlv?.aircraft_id)?.aircraft_registration;
          
          toast.success(`The task card for ${curAircraft} was created!`);
        });
      }
    } else {
      const formattedData = Object.keys(dirtyFields).reduce((res, key) => ({ ...res, [key]: data[key] }), {});
      const res = await update({ data: formattedData, id: params.id });

      if (res.error) {
        toast.error(res.error.data.message || 'Something went wrong', {
          id: toastId
        });

        return;
      }

      reset(res.data);
      toast.success('Atrmaintenance was updated!', { id: toastId });
    }
  };

  const getFileList = async (id, callback) => {
    const res = await getFiles({ id });

    if (res?.error) {
      toast.error('Unable to get file list.');

      callback([]);

      return;
    }

    callback(res?.data || []);
  };

  const handleUpdateFiles = async (id, file, type, callback) => {
    if (!file.target.files[0]) return;

    const toastId = toast.loading('Loading...');
    const formData = new FormData();

    Object.keys(file.target.files)?.forEach((key) => {
      formData.append('upload_files[]', file.target.files[key]);
    });

    const res = await updateFiles({ id, data: formData });

    if (res?.error) {
      toast.error('Something went wrong.', {
        id: toastId,
      });

      return;
    }

    toast.success('Success!', {
      id: toastId,
    });

    callback();
  };

  const handleFileDrawer = (isOpen, id = null, type = null, name = '', currentName = '') => {
    if (id) {
      getFileList(id, (list) => setFileDrawerState(prev => ({
        ...prev,
        isOpen,
        type,
        currentId: id,
        fileList: setFilesName(list || []),
        name,
        currentName
      })));
    }

    setFileDrawerState(prev => ({
      ...prev,
      isOpen,
      type,
      fileList: [],
      currentId: id,
      name,
      currentName
    }));
  };

  useEffect(() => {
    if (isUpdate) {
      handleFlightData();
    }
  }, [isUpdate]);

  if (isLoadingMaintenance) return <ContentPageLoader />;

  return (
    <Box>
      <Box sx={headerSx}>
        <ChevronLeftIcon sx={backIcon} onClick={handleBack} />
        <h1>
          Fleetair -
          { isUpdate ? ' Update' : ' Create' }
          {' '}
          Atrmaintenance
          {' '}
          {isUpdate ? '' : `(${params.version.charAt(0).toUpperCase() + params.version.slice(1)})`}
        </h1>
      </Box>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box sx={formContainer}>
            <General
              disabled={watch('is_checked')}
              isUpdate={isUpdate}
              handleFileDrawer={handleFileDrawer}
              currentId={params?.id}
              uploadFile={uploadFile}
              nameFile={nameFile}
              aircraft={aircraft}
              setSelectAircraft={setSelectAircraft}
              selectAircraft={selectAircraft}
            />
            <LastDone disabled={watch('is_checked')} />
            <Interval disabled={watch('is_checked')} />
            <DueAtAc disabled={watch('is_checked')} />
            <Remaining />
            <Status />
          </Box>

          <Card sx={cardContainer}>
            <Button sx={saveBtn} disabled={isLoadingCreate || isLoadingUpdate || !hasPermissionsMaintenance} type="submit" title={isUpdate ? 'Update' : 'Create'} />
            {/* <Button sx={saveBtn} onClick={resetForm} title="Reset" /> */}
            <Button sx={cancelBtn} onClick={handleBack} title="Cancel" />
          </Card>
        </form>
      </FormProvider>

      <FileDrawer
        open={fileDrawerState.isOpen}
        onClose={() => handleFileDrawer(false)}
        title={fileDrawerState.name}
        itemName={fileDrawerState.currentName}
        itemsList={fileDrawerState.fileList}
        uploadFile={(file) => handleUpdateFiles(params?.id, file, fileDrawerState.type, () => handleFileDrawer(true, params?.id, fileDrawerState.type, fileDrawerState.name, fileDrawerState.currentName))}
        isAttachLoading={isAttachLoading}
        hasPermissions
        isLoadingFiles={isGetFilesLoading}
        useOpenFileMutation={useOpenRefFileMaintenanceMutation}
        useDeleteFileMutation={useDeleteRefFileMaintenanceMutation}
        downloadButton
      />
    </Box>

  );
};

export default AtrMaintenanceForm;
