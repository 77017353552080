import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  TextField,
  Typography
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { useParams } from 'react-router-dom';
import ContentPageLoader from '../../../components/Loader/ContentPageLoader';
import FormGroup from '../../../form/components/FormGroup';
import { useGetAircraftTypeMutation, useStoreAircraftTypeMutation, useUpdateAircraftTypeMutation } from '../../../store/session';
import { defaultValues, schema } from './schema';

const AircraftTypeForm = () => {
  const [isLoading, setLoading] = useState(false);
  const { id } = useParams();
  const update = id !== 'create';
  const [updateAircraftType, { isLoading: isLoadingUpdate }] = useUpdateAircraftTypeMutation();
  const [storeAircraftType, { isLoading: isLoadingCreate }] = useStoreAircraftTypeMutation();
  const [getAircraftType] = useGetAircraftTypeMutation();
  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors }
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(schema),
    defaultValues,
  });

  const isLoadingRequest = update ? isLoadingUpdate : isLoadingCreate;

  const handleAircraftTypeData = async () => {
    setLoading(true);
    const { data: result } = await getAircraftType({ id });

    return result;
  };
  useEffect(() => {
    if (update) {
      handleAircraftTypeData().then((result) => {
        if (result) {
          Object.entries(result).forEach(
            ([name, value]) => setValue(name, value),
          );
        }
        setLoading(false);
      });
    }
  }, [update]);

  if (isLoading) {
    return <ContentPageLoader />;
  }
  const onSubmit = async (data) => {
    const toastId = toast.loading('Loading...');
    let res; 

    if (update) {
      res = await updateAircraftType({
        id: data.id,
        aircraft_type_name: data.aircraft_type_name,
        aircraft_display_name: data.aircraft_display_name,
      });
    } else {
      res = await storeAircraftType({
        aircraft_type_name: data.aircraft_type_name,
        aircraft_display_name: data.aircraft_display_name,
      });
    }

    if (res?.error) {
      toast.error(res?.error.data.message || 'Something went wrong', {
        id: toastId,
      });

      return;
    }

    toast.success('Successfully!', {
      id: toastId,
    });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Card sx={{ mt: 3 }}>
        <Box sx={{ padding: '24px 0 0 24px' }}>
          <Typography variant="h3">Aircraft Type</Typography>
        </Box>
        {/* <CardHeader title="Aircraft Type" /> */}
        <CardContent sx={{ mt: 5 }}>

          <Grid container spacing={2} sx={{ flexDirection: 'column' }}>
            <Grid item xs={6}>
              <FormGroup label="Aircraft Type Name" required hasError={!!errors.aircraft_type_name}>
                <Controller
                  control={control}
                  name="aircraft_type_name"
                  render={({ field }) => (
                    <TextField
                      {...field}
                      size="small"
                      placeholder="Aircraft Type Name"
                      sx={{ width: '100%' }}
                    />
                  )}
                />
              </FormGroup>
            </Grid>
            <Grid item xs={6}>
              <FormGroup label="Aircraft Notifications Display Name" required hasError={!!errors.aircraft_display_name}>
                <Controller
                  control={control}
                  name="aircraft_display_name"
                  render={({ field }) => (
                    <TextField
                      {...field}
                      size="small"
                      placeholder="Aircraft Notifications Display Name"
                      sx={{ width: '100%' }}
                    />
                  )}
                />
              </FormGroup>
            </Grid>
          </Grid>

          <Grid item xs={6}>
            <Button 
              type="submit"
              variant="contained" 
              size="large" 
              disabled={isLoadingRequest}
            >
              {update ? 'Update' : 'Save' }
            </Button>
          </Grid>

        </CardContent>
      </Card>
    </form>
  );
};

export default AircraftTypeForm;
