import { PriceCheck } from '@mui/icons-material';
import CloseIcon from '@mui/icons-material/Close';
import {
  Box,
  Card,
  Divider,
  Grid,
  InputAdornment,
  TextField,
  Typography
} from '@mui/material';
import { Plus, Rename } from 'mdi-material-ui';
import React, { useEffect, useState } from 'react';
import { Controller, FormProvider } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import Button from '../../../../../../components/Button';
import Autocomplete from '../../../../../../form/components/Autocomplete/Autocomplete';
import Checkbox from '../../../../../../form/components/Checkbox';
import DatePicker from '../../../../../../form/components/DatePicker';
import FormGroup from '../../../../../../form/components/FormGroup';
import Input from '../../../../../../form/components/Input';
import Select from '../../../../../../form/components/Select';
import ServerAutocomplete from '../../../../../../form/components/ServerAutocomplete';
import { useSearchSupplierQuery } from '../../../../../../store/slices/logisticsSlice';
import { usePermissions } from '../../../../../../utils/hooks/usePermissions';
import { setDateValue, validateDatePickerValue } from '../../../../../../utils/setDateValue';
import { btnS, headerBtn } from '../../../../../Invoices/style';
import { externalOrdersType } from '../../../../constants';
import { content } from '../../../../MyOrders/NewOrder/components/style';
import {
  Filters, GreenButton, RedButton, TextWithIcon
} from '../../../../style';
import { getOrderType } from '../../../../utils';
import { PRICES_STATUS, TRANSACTION_TYPE } from './schema';
import { useNewOrderForm } from './useNewOrderForm';

const NewOrderForm = ({ onClose, aircraftOptions }) => {
  const { order: currentOrder, currencies } = useSelector((state) => state.logistics);
  const { order: internalOrder } = useSelector((state) => state.internalLogistics);
  const location = useLocation();
  const dashboardType = location.pathname.split('/')[3];
  const order = dashboardType === 'orders' ? currentOrder : internalOrder;
  const userAccess = usePermissions('logist');
  const internalLogist = usePermissions('internal_logist');
  const [supplier, setSupplier] = useState({});
  const {
    formMethods,
    prices,
    defaultValues,
    handleSubmit,
    onSubmit,
    isLoading,
    setValue
  } = useNewOrderForm({ order, onClose, dashboardType });

  const { errors } = formMethods.formState;

  const addressSelectOptions = [
    { label: 'Hof', value: 'hof' },
    { label: 'Budapest', value: 'budapest' },
    ...aircraftOptions
  ].filter(({ label }) => label !== 'N/A');

  const handleChangeAddress = (data, key) => {
    if (data === 'hof') {
      setValue(
        key,
        'Fleet Air International Kft\nPirk 20 Flughafen Hof-Plauen GmbH & Co. KG\nHOF, SAALE\n95032\nGermany'
      );
    } else if (data === 'budapest') {
      setValue(
        key,
        'Fleet Air International Kft\n2220 Vecses\nFo ut 218\nHungary'
      );
    } else {
      setValue(key, addressSelectOptions.find(({ value }) => data === value)?.label);
    }
  };
 
  useEffect(() => {
    setValue('prices.[0].orderType', order?.part_type);
    setValue('orderType', order?.part_type);
  }, [order]);

  if ((dashboardType === 'orders') ? !userAccess : !internalLogist) return <Typography sx={{ margin: '20px 0', textAlign: 'center' }}>You don&apos;t have access.</Typography>;

  return (
    <FormProvider {...formMethods}>
      <form>
        <Card
          sx={{ padding: '0rem 1rem 1rem 1rem' }}
        >
          <Box sx={headerBtn}>
            <Box>
              <Typography variant="h5" noWrap>
                Submit for approval
              </Typography>
              <Typography variant="subtitle2">
                Please indicate the prices for this order. The approver will choose only one.
              </Typography>
            </Box>
          </Box>

          <Grid container>
            <Grid item xs={12}>
              <FormGroup label="Notice" hasError={!!errors.storage}>
                <Controller
                  name="notice"
                  control={formMethods.control}
                  render={({ field }) => (
                    <TextField
                      id="outlined-multiline-static"
                      placeholder="You can indicate some important notes"
                      row={6}
                      multiline
                      {...field}
                      sx={{ width: '100%', color: 'rgba(76, 78, 100, 0.87);' }}
                    />
                  )}
                />
              </FormGroup>
            </Grid>

            <Grid item xs={12}>
              <Box sx={content}>
                <Typography noWrap variant="h6" sx={{ ...TextWithIcon, textTransform: 'capitalize' }}>
                  <PriceCheck />
                  {['Overhaul_out', 'Repair_out'].includes(order?.part_type) && getOrderType(order?.part_type)}
                  {' '}
                  Prices
                </Typography>

                {prices.fields.map((item, index) => (
                  <Grid
                    container
                    sx={{
                      // display: 'flex',
                      backgroundColor: 'white',
                      padding: '15px',
                      borderRadius: '8px'
                    }}
                    spacing={2}
                    key={item.id}
                  >
                    <Grid item xs={12} lg={12}>
                      <Box sx={headerBtn}>
                        <Box>
                          <Typography noWrap variant="h6" sx={{ textTransform: 'capitalize' }}>
                            {['Overhaul_out', 'Repair_out'].includes(order?.part_type) && getOrderType(order?.part_type)}
                            {' '}
                            Price #
                            {index + 1}
                          </Typography>
                        </Box>

                        {index !== 0 && (
                          <Button
                            sx={{ ...(RedButton), fontWeight: 600 }}
                            endIcon={<CloseIcon />}
                            size="small"
                            title="Delete"
                            onClick={() => {
                              prices.remove(index);
                              setSupplier(prev => {
                                const a = { ...prev };
                                delete a[index];

                                return a;
                              });
                            }}
                          />
                        )}
                      </Box>
                    </Grid>

                    {!['Overhaul_out', 'Repair_out', 'delivery', 'Other'].includes(order?.part_type) && (
                    <Grid item xs={4}>
                      <FormGroup label="Transaction Type" required hasError={!!errors.prices?.[index]?.transaction_type}>
                        <Controller
                          control={formMethods.control}
                          name={`prices.${[index]}.transaction_type`}
                          render={({ field }) => (
                            <Select
                              options={TRANSACTION_TYPE}
                              placeholder="Transaction"
                              {...field}
                            />
                          )}
                        />
                      </FormGroup>
                    </Grid>
                    )}
                    {!['Overhaul_out', 'Repair_out'].includes(order?.part_type) && (
                    <Grid item xs={4}>
                      <FormGroup
                        label={(formMethods?.watch('prices')?.[index]?.transaction_type === 'exchange')
                          ? 'Core Value' : 'Amount'}
                        required
                        hasError={errors?.prices?.[index]?.amount}
                      >
                        <Controller
                          name={`prices.${[index]}.amount`}
                          control={formMethods.control}
                          render={({ field }) => (
                            <TextField
                              {...field}
                              size="small"
                              type="number"
                              placeholder={!['Overhaul_out', 'Repair_out'].includes(order?.part_type) ? ((formMethods?.watch('prices')?.[index]?.transaction_type === 'exchange') ? 'Core Value' : 'Amount') : 'Estimated Price'}
                              sx={{ width: '100%' }}
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    <Rename sx={{ ...(field?.value && { color: '#626477' }) }} />
                                  </InputAdornment>
                                ),
                              }}
                              onChange={(e) => {
                                if (+e?.target?.value > 999999999) return;
                                field.onChange(+e?.target?.value || '');
                              }}
                            />
                          )}
                        />
                      </FormGroup>
                    </Grid>
                    )}

                    {!['Overhaul_out', 'Repair_out'].includes(order?.part_type) && (
                    <Grid item xs={4}>
                      <FormGroup label="Currency" required hasError={!!errors.prices?.[index]?.currency}>
                        <Controller
                          control={formMethods.control}
                          name={`prices.${[index]}.currency`}
                          render={({ field }) => (
                            <Autocomplete
                              {...field}
                              value={field.value ? currencies?.filter(cur => ((cur?.value === field?.value) || (cur?.value === field?.value?.value)))[0] : null}
                              options={currencies}
                              size="small"
                              placeholder="Please select a currency"
                              onChange={(e, value ) => {
                                field.onChange(value?.value);
                              }}
                            />
                          )}
                        />
                      </FormGroup>
                    </Grid>
                    )}
                    {!['Overhaul_out', 'Repair_out', 'delivery', 'Other'].includes(order?.part_type) && (
                      <Grid item xs={12}>
                        <FormGroup label="Vendor" required hasError={!!errors.prices?.[index]?.supplier_id}>
                          <Controller
                            control={formMethods.control}
                            name={`prices.${[index]}.supplier_id`}
                            render={({ field }) => (
                              <ServerAutocomplete
                                value={supplier[index]}
                                withData
                                placeholder="Search suppliers by name or email"
                                searchQueryFn={useSearchSupplierQuery}
                                handleSelect={(value) => {
                                  field.onChange(value?.value);
                                  setSupplier({ ...supplier, [index]: value });
                                }}
                              />
                            )}
                          />
                        </FormGroup>
                      </Grid>
                    )}
                    {['delivery', 'Other'].includes(order?.part_type) && (
                      <Grid item xs={12}>
                        <FormGroup label="Delivery Operators" required hasError={!!errors.prices?.[index]?.supplier_id}>
                          <Controller
                            control={formMethods.control}
                            name={`prices.${[index]}.supplier_id`}
                            render={({ field }) => (
                              <ServerAutocomplete
                                value={supplier[index]}
                                withData
                                placeholder="Search suppliers by name or email"
                                searchQueryFn={useSearchSupplierQuery}
                                handleSelect={(value) => {
                                  field.onChange(value?.value);
                                  setSupplier({ ...supplier, [index]: value });
                                }}
                              />
                            )}
                          />
                        </FormGroup>
                      </Grid>
                    )}
                    {!['Overhaul_out', 'Repair_out', 'delivery', 'Other'].includes(order?.part_type) && (
                    <Grid item xs={4}>
                      <FormGroup label="Status" required hasError={!!errors.prices?.[index]?.status}>
                        <Controller
                          control={formMethods.control}
                          name={`prices.${[index]}.status`}
                          render={({ field }) => (
                            <Select
                              options={PRICES_STATUS}
                              placeholder="Status"
                              sx={{ width: '100%' }}
                              {...field}
                            />
                          )}
                        />
                      </FormGroup>
                    </Grid>
                    )}

                    {/* Address Form */}

                    {['Overhaul_out', 'Repair_out', 'Return'].includes(order?.part_type) && (
                    <Grid
                      container
                      spacing={2}
                      sx={{
                        margin: '0',
                      }}
                    >
                      <Grid item xs={5}>
                        <FormGroup label="From">
                          <Controller
                            name={`prices.${[index]}.from`}
                            control={formMethods.control}
                            render={({ field }) => (
                              <Select
                                options={addressSelectOptions}
                                placeholder="From"
                                {...field}
                                onChange={(e) => {
                                  handleChangeAddress(e?.target?.value, `prices.${[index]}.address_from`);
                                  field?.onChange(e?.target?.value);
                                }}
                              />
                            )}
                          />
                        </FormGroup>
                      </Grid>
                      <Grid item xs={7}>
                        <FormGroup label="From Address" required hasError={errors?.prices?.[index]?.address_from}>
                          <Controller
                            name={`prices.${[index]}.address_from`}
                            control={formMethods.control}
                            render={({ field }) => (
                              <Input
                                {...field}
                                multiline
                                sx={{ width: '100%' }}
                              />
                            )}
                          />
                        </FormGroup>
                      </Grid>
                      <Grid item xs={5}>
                        <FormGroup label="To">
                          <Controller
                            name={`prices.${[index]}.to`}
                            control={formMethods.control}
                            render={({ field }) => (
                              <ServerAutocomplete
                                field={field}
                                value={supplier[index]}
                                withData
                                placeholder="Search suppliers by name or email"
                                searchQueryFn={useSearchSupplierQuery}
                                queryParams={{ type: 'service' }}
                                handleSelect={(value) => {
                                  field.onChange(value?.value);
                                  setSupplier({ ...supplier, [index]: value });
                                  setValue(`prices.${[index]}.address_to`, value?.data?.actual_address || value?.data?.label);
                                  setValue(`prices.${[index]}.address_to_supplier_id`, value?.data?.id);
                                }}
                              />
                            )}
                          />
                        </FormGroup>
                      </Grid>
                      <Grid item xs={7}>
                        <FormGroup label="To Address" required hasError={errors?.prices?.[index]?.address_to}>
                          <Controller
                            name={`prices.${[index]}.address_to`}
                            control={formMethods.control}
                            render={({ field }) => (
                              <Input
                                {...field}
                                multiline
                                sx={{ width: '100%' }}
                              />
                            )}
                          />
                        </FormGroup>
                      </Grid>
                    </Grid>
                    ) }

                    <Grid item xs={4}>
                      <FormGroup label="Estimated Delivery Date" required={['Repair_out', 'Overhaul_out'].includes(order?.part_type)} hasError={!!errors.prices?.[index]?.estimated_date}>
                        <Controller
                          control={formMethods.control}
                          name={`prices.${[index]}.estimated_date`}
                          render={({ field }) => (
                            <DatePicker
                              placeholderText="YYYY-MM-DD"
                              dateFormat="yyyy-MM-dd"
                              showMonthDropdown
                              showYearDropdown
                              {...field}
                              onChange={(e) => {
                                field.onChange(e);
                                setDateValue(e, setValue, `prices.${[index]}.estimated_date`, 'YYYY-MM-DD');
                              }}
                              value={validateDatePickerValue(field.value)}
                            />
                          )}
                        />
                      </FormGroup>
                    </Grid>

                    {externalOrdersType.includes(order?.part_type) && (
                      <>
                        <Grid item xs={4}>
                          <FormGroup label="Estimated Delivery Price" hasError={!!errors.prices?.[index]?.estimated_delivery_price}>
                            <Controller
                              control={formMethods.control}
                              name={`prices.${[index]}.estimated_delivery_price`}
                              render={({ field }) => (
                                <TextField
                                  {...field}
                                  size="small"
                                  type="number"
                                  placeholder="Estimated Delivery Price"
                                  sx={{ width: '100%' }}
                                  InputProps={{
                                    startAdornment: (
                                      <InputAdornment position="start">
                                        <Rename sx={{ ...(field?.value && { color: '#626477' }) }} />
                                      </InputAdornment>
                                    ),
                                  }}
                                  onChange={(e) => {
                                    if (+e?.target?.value > 999999999) return;
                                    field.onChange(+e?.target?.value || '');
                                  }}
                                />
                              )}
                            />
                          </FormGroup>
                        </Grid>

                        <Grid item xs={4}>
                          <FormGroup label="Estimated Delivery Currency" hasError={!!errors.prices?.[index]?.estimated_delivery_currency_id}>
                            <Controller
                              control={formMethods.control}
                              name={`prices.${[index]}.estimated_delivery_currency_id`}
                              render={({ field }) => (
                                <Autocomplete
                                  {...field}
                                  value={field.value ? currencies?.filter(cur => (+cur?.value === +field?.value))?.[0] : null}
                                  options={currencies}
                                  size="small"
                                  placeholder="Please select a Estimated Delivery Currency"
                                  onChange={(e, value ) => {
                                    field.onChange(+value?.value);
                                  }}
                                />
                              )}
                            />
                          </FormGroup>
                        </Grid>
                      </>
                    )}

                    {(formMethods?.watch('prices')?.[index]?.transaction_type === 'exchange') && (
                    <Grid item xs={4}>
                      <FormGroup
                        label="Exchange Fee"
                        required
                        hasError={errors?.prices?.[index]?.exchange_fee}
                      >
                        <Controller
                          name={`prices.${[index]}.exchange_fee`}
                          control={formMethods.control}
                          render={({ field }) => (
                            <TextField
                              {...field}
                              size="small"
                              type="number"
                              placeholder="Exchange Fee"
                              sx={{ width: '100%' }}
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    <Rename sx={{ ...(field?.value && { color: '#626477' }) }} />
                                  </InputAdornment>
                                ),
                              }}
                              onChange={(e) => {
                                if (e?.target?.value?.toString()?.length > 10) return;
                                field.onChange(+e?.target?.value || '');
                              }}
                            />
                          )}
                        />
                      </FormGroup>
                    </Grid>
                    )}

                    <Grid item xs={12}>
                      <FormGroup label="Suggested Price" required hasError={!!errors.prices?.[index]?.suggested}>
                        <Controller
                          name={`prices.${[index]}.suggested`}
                          control={formMethods.control}
                          render={({ field }) => (
                            <Checkbox
                              {...field}
                            />
                          )}
                        />
                      </FormGroup>
                    </Grid>
                  </Grid>
                ))}
              </Box>

              <Grid container sx={{ mt: 2.75, mb: 2.75, justifyContent: 'center' }}>
                <Grid item xs={4} sx={{ px: 0 }}>
                  <Button
                    title="Add Price"
                    startIcon={<Plus />}
                    sx={{ ...btnS, ...GreenButton }}
                    size="small"
                    onClick={() => prices.append({
                      ...defaultValues.prices[0],
                      orderType: order?.part_type
                    })}
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <Divider />
              <Box sx={{ ...(Filters), padding: '0px', justifyContent: 'space-between' }}>
                <Box>
                  <Button
                    sx={{ mt: 2, ...(RedButton) }}
                    variant="contained"
                    size="large"
                    title="Cancel"
                    onClick={onClose}
                  />
                </Box>
                <Box>
                  <Button
                    sx={{ mt: 2, ...(GreenButton) }}
                    variant="contained"
                    size="large"
                    disabled={isLoading}
                    onClick={handleSubmit(onSubmit)}
                    title="Submit"
                  />
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Card>
      </form>
    </FormProvider>
  );
};

export default NewOrderForm;
