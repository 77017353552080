import {
  Grid, MenuItem, Select, Typography, useMediaQuery
} from '@mui/material';
import React, { useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import Button from '../../../../../components/Button';
import Upload from '../../../../../components/Upload';
import AutoSizeTextarea from '../../../../../form/components/AutoSizeTextarea';
import FieldError from '../../../../../form/components/FieldError';
import FormGroup from '../../../../../form/components/FormGroup';
import Input from '../../../../../form/components/Input';
import TimeInput from '../../../../../form/components/TimeInput';
import { file, fileName, label } from '../../../../Logistics/OrdersDashboard/components/forms/approved/invoices/style';
import TypeCard from '../../../../Ods/OdsForm/TypeCard';
import { MenuProps } from '../../../UtilizationReport/style';

const General = ({
  isUpdate, disabled, handleFileDrawer, currentId, uploadFile, nameFile, aircraft, selectAircraft, setSelectAircraft
}) => {
  const {
    control, formState: { errors }, setValue
  } = useFormContext();

  const maxWidth768px = useMediaQuery('(max-width:768px)');
  const aircraftOptions = (aircraft || []).map((item) => ({ value: +item?.id, label: item?.aircraft_registration }));

  useEffect(() => {
    if (currentId && !isUpdate) {
      setValue('aircraft_ids', [+currentId]);
    }
  }, [currentId]);

  useEffect(() => {
    if (aircraftOptions.length && !selectAircraft.length && !isUpdate) {
      setSelectAircraft([+currentId]);
    }
  }, [aircraftOptions, selectAircraft, isUpdate]);

  return (
    <TypeCard title="GENERAL" variant="yellow">
      <Grid xs={12} container spacing={4} rowSpacing={2} sx={{ flexGrow: 1 }}>
        {!isUpdate && (
        <Grid item xs={maxWidth768px ? 12 : 3}>
          <FormGroup label="Aircraft" hasError={!!errors?.aircraft_ids}>
            <Controller
              control={control}
              name="aircraft_ids"
              render={({ field } ) => (
                <Select
                  {...field}
                  multiple
                  value={selectAircraft}
                  onChange={(e) => {
                    if (!e?.target?.value?.length) {
                      field.onChange([+currentId]);
                      setSelectAircraft([+currentId]);
                    } else {
                      const isCurrent = e.target.value.includes(+currentId);

                      if (!isCurrent) {
                        field.onChange([+currentId, ...e.target.value]);
                        setSelectAircraft([+currentId, ...e.target.value]);
                      } else {
                        field.onChange(e.target.value);
                        setSelectAircraft(e.target.value);
                      }
                    }
                  }}
                  MenuProps={MenuProps}
                  sx={{
                    width: '100%',
                    height: '2.5rem'
                  }}
                >
                  {(aircraftOptions || []).map(({ label: optLabel, value }) => (
                    <MenuItem
                      key={value}
                      value={value}
                      sx={{ fontWeight: 'bold' }}
                    >
                      {optLabel}
                    </MenuItem>
                  ))}
                </Select>
              )}
            />

            <FieldError error={errors?.aircraft_ids} />
          </FormGroup>
        </Grid>
        )}
        <Grid item xs={maxWidth768px ? 12 : 3}>
          <FormGroup required={!isUpdate} label="TASK NUMBER" hasError={!!errors?.task_number}>
            <Controller
              render={({ field } ) => (
                <Input
                  placeholder="Enter task number"
                  disabled={disabled}
                  {...field}
                />
              )}
              control={control}
              name="task_number"
            />

            <FieldError error={errors?.task_number} />
          </FormGroup>
        </Grid>
        <Grid item xs={maxWidth768px ? 12 : 3}>
          <FormGroup label="PART NUMBER">
            <Controller
              render={({ field } ) => (
                <Input
                  placeholder="Enter part name"
                  disabled={disabled}
                  {...field}
                />
              )}
              control={control}
              name="part_number"
            />
          </FormGroup>
        </Grid>
        <Grid item xs={maxWidth768px ? 12 : 3}>
          <FormGroup label="SERIAL NUMBER">
            <Controller
              render={({ field } ) => (
                <Input
                  placeholder="Enter serial number"
                  disabled={disabled}
                  {...field}
                />
              )}
              control={control}
              name="serial_number"
            />
          </FormGroup>
        </Grid>
        <Grid item xs={maxWidth768px ? 12 : 3}>
          <FormGroup label="PART NAME">
            <Controller
              render={({ field } ) => (
                <Input
                  placeholder="Enter part name"
                  disabled={disabled}
                  {...field}
                />
              )}
              control={control}
              name="part_name"
            />
          </FormGroup>
        </Grid>
      </Grid>
      <Grid xs={12} container spacing={5} rowSpacing={2} sx={{ flexGrow: 1 }}>
        <Grid item xs={maxWidth768px ? 12 : 6}>
          <FormGroup label="TITLE" required hasError={!!errors?.title}>
            <Controller
              render={({ field } ) => (
                <Input
                  placeholder="Enter title"
                  disabled={disabled}
                  {...field}
                />
              )}
              control={control}
              name="title"
            />

            <FieldError error={errors?.title} />
          </FormGroup>
        </Grid>
        <Grid item xs={maxWidth768px ? 12 : 3}>
          <FormGroup label="REFERENCE">
            <Controller
              render={({ field } ) => (
                <Input
                  placeholder="Enter task number"
                  disabled={disabled}
                  {...field}
                />
              )}
              control={control}
              name="reference"
            />
          </FormGroup>
        </Grid>
        <Grid item xs={maxWidth768px ? 12 : 3}>
          {isUpdate ? (
            <FormGroup label="Ref Files">
              <Button title="Open Files" onClick={() => handleFileDrawer(true, currentId, '', 'Ref', '')} />
            </FormGroup>
          ) : (
            <Grid item xs={12} sx={file}>
              <FormGroup sx={label} label="Ref Files" hasError={!!errors.upload_files}>
                <Controller
                  name="upload_files"
                  control={control}
                  render={({ field }) => (
                    <Upload
                      {...field}
                      accept=".doc,.docx,image/*,.pdf"
                      multiple
                      handleUpload={(e) => {
                        field.onChange(e);
                        uploadFile(e);
                      }}
                      title="Upload file"
                    />
                  )}
                />
                <FieldError error={errors.upload_files} />
              </FormGroup>
              <Typography sx={fileName}>{nameFile}</Typography>
            </Grid>
          )}
        </Grid>
      </Grid>

      <Grid xs={12} container spacing={5} rowSpacing={2} sx={{ flexGrow: 1 }}>
        <Grid item xs={12}>
          <FormGroup label="DESCRIPTION" required hasError={!!errors?.description}>
            <Controller
              render={({ field } ) => (
                <Input
                  placeholder="Enter description"
                  disabled={disabled}
                  {...field}
                />
              )}
              control={control}
              name="description"
            />

            <FieldError error={errors?.description} />
          </FormGroup>
        </Grid>
      </Grid>
      <Grid xs={12} container spacing={5} rowSpacing={2} sx={{ flexGrow: 1 }}>
        <Grid item xs={12}>
          <FormGroup label="WO ISSUED">
            <Controller
              render={({ field } ) => (
                <AutoSizeTextarea
                  disabled={disabled}
                  {...field}
                  placeholder="Enter wo issued"
                  rows={7}
                />
              )}
              control={control}
              name="wo_issued"
            />
          </FormGroup>
        </Grid>
      </Grid>
      <Grid xs={12} container spacing={5} rowSpacing={2} sx={{ flexGrow: 1 }}>
        <Grid item xs={maxWidth768px ? 12 : 6}>
          <FormGroup label="ZONE">
            <Controller
              render={({ field } ) => (
                <Input
                  placeholder="Enter position"
                  disabled={disabled}
                  {...field}
                />
              )}
              control={control}
              name="position"
            />
          </FormGroup>
        </Grid>
        <Grid item xs={maxWidth768px ? 12 : 6}>
          <FormGroup label="TO DO">
            <Controller
              render={({ field } ) => (
                <Input
                  placeholder="Enter to do"
                  disabled={disabled}
                  {...field}
                />
              )}
              control={control}
              name="to_do"
            />
          </FormGroup>
        </Grid>
        <Grid item xs={maxWidth768px ? 12 : 2}>
          <FormGroup label="MAN HOUR" hasError={!!errors?.man_seconds}>
            <Controller
              render={({ field } ) => (
                <TimeInput
                  disabled={disabled}
                  {...field}
                  variant="standard"
                  helperText="Approximate completion time in hours"
                />
              )}
              control={control}
              name="man_seconds"
            />
            <FieldError error={errors?.man_seconds} />
          </FormGroup>
        </Grid>
      </Grid>
    </TypeCard>
  );
};

export default General;
