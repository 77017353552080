import React from 'react';
import Drawer from '../../../../components/Drawer';
import InvoicesForm from '../components/forms/approved/invoices/InvoicesForm';

const NewInvoice = ({ open, onClose, dashboardType }) => 
// const [activeTab, setActiveTab] = useState('part');

// const handleTabChange = (_, newValue) => {
//   setActiveTab(newValue);
// };

// useEffect(() => {
//   if (open) setActiveTab((dashboardType === 'orders') ? 'part' : 'delivery');
// }, [open, dashboardType]);

  (
    <Drawer
      title="Invoice Info"
      open={open}
      onClose={onClose}
      sx={{
        '& .MuiPaper-rounded': {
          overflowY: 'auto'
        }
      }}
    >
      {/* <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs
          sx={{ mt: 0, pt: 0 }}
          value={activeTab}
          onChange={handleTabChange}
          variant="fullWidth"
          centered
          aria-label="basic tabs example"
        >
          {(dashboardType === 'orders') && <Tab value="part" label="Parts Invoices" />}
          <Tab value="delivery" label="Delivery Invoices" />
          {(dashboardType === 'delivery') && <Tab value="overhaul" label="Overhaul/Repair Invoices" />}
          {(dashboardType === 'orders') && <Tab value="exchange" label="Exchange Invoices" />}
        </Tabs>
      </Box> */}

      <InvoicesForm order={null} variant={null} drawerClose={onClose} dashboardType={dashboardType} />
    </Drawer>
  );
export default NewInvoice;
