import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import {
  Box,
  CardContent,
  Chip,
  Divider,
  Typography
} from '@mui/material';
import React, { useCallback } from 'react';
import { headerBtn, headerBtnS } from '../../../../Invoices/style';
import { Item, TextWithIcon } from '../../../../Logistics/style';
// eslint-disable-next-line import/named
import DownloadIcon from '@mui/icons-material/Download';
import toast from 'react-hot-toast';
import { maxWidth1500px } from '../../../../../constans/mediaQuery';
import { usePermissions } from '../../../../../utils/hooks/usePermissions';
import { StatusText } from '../../../../Logistics/OrdersDashboard/styles';
import { CardAction } from '../../styles';

const DashboardInvoiceCard = ({
  props, invoice, onOpen, permissions
}) => {
  const formattedInvoice = props.formatInvoice(invoice);
  const hasPermission = usePermissions(permissions);

  let background = (formattedInvoice.status !== 3 && formattedInvoice.is_expired_warning) ? '#fff0e4' : null;
  if (formattedInvoice.status === 7) {
    background = null;
  } else if (formattedInvoice.status !== 3 && formattedInvoice.is_expired) {
    background = '#ffe4e4';
  } else if (formattedInvoice.status === 1) {
    background = '#fff7e4';
  }

  const handleCheckPermission = (callback) => {
    if (hasPermission) {
      callback();
    } else {
      toast.error('You do not have access!', {
        duration: 3000
      });
    }
  };

  const renderSupplier = useCallback(() => {
    const getSupplierName = () => {
      if (formattedInvoice?.invoice_name) return formattedInvoice.invoice_name;
      if (formattedInvoice?.invoice_supplier) return formattedInvoice.invoice_supplier;
      const orderWithSupplier = formattedInvoice?.orders?.find(order => order?.prices?.some(price => price?.approved_by && price?.supplier?.name));

      return orderWithSupplier?.prices?.find(price => price?.approved_by && price?.supplier?.name)?.supplier?.name || null;
    };

    const supplierName = getSupplierName();

    return (
      <Typography noWrap variant="body1">
        <b>Supplier:</b>
        {' '}
        {supplierName}
      </Typography>
    );
  }, [formattedInvoice]);

  return (
    <Item
      onClick={() => handleCheckPermission(() => onOpen(formattedInvoice.id))}
      sx={{ background }}
    >
      <CardContent sx={{ position: 'relative' }}>
        <Box sx={{
          ...headerBtn, ...(maxWidth1500px() && headerBtnS), display: 'flex', justifyContent: 'space-between'
        }}
        >
          <Box>
            <Typography noWrap variant="subtitle1" sx={StatusText}>
              {formattedInvoice.number}
              {(((formattedInvoice.status !== 3) && (formattedInvoice.status !== 7)) && (formattedInvoice.is_expired_warning || formattedInvoice.is_expired)) ? <PriorityHighIcon sx={{ color: 'red' }} /> : ''}
              {(invoice?.resolved_at && (invoice?.payment_status === 3)) && <Chip label="Resolved" size="small" color="success" />}
            </Typography>
          </Box>

          <Box sx={TextWithIcon}>
            {formattedInvoice.type.icon}
            {formattedInvoice.type.label}
          </Box>
        </Box>

        <Divider />

        <Box>
          <Typography noWrap variant="body1">
            <b>Number:</b>
            {' '}
            {formattedInvoice.invoice_number}
          </Typography>
          {renderSupplier()}
          <Typography noWrap variant="body1">
            <b>Amount:</b>
            {' '}
            {formattedInvoice.amount}
          </Typography>
          {(formattedInvoice.due_date && (
            <Typography noWrap variant="body1">
              <b style={{ color: '#1c4675' }}>Due date:</b>
              {' '}
              {formattedInvoice.due_date}
            </Typography>
          ))}
          {(formattedInvoice.purpose && (
            <Typography noWrap variant="body1">
              <b>Purpose:</b>
              {' '}
              {formattedInvoice.purpose}
            </Typography>
          ))}
          <Typography noWrap variant="body1">
            <b>Sender:</b>
            {' '}
            {formattedInvoice.sender}
          </Typography>
        </Box>

        <Divider />

        <Box
          sx={CardAction}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            handleCheckPermission(() => props.handleDownloadInvoice({ id: invoice.id, file_name: invoice.file_name }));
          }}
        >
          <DownloadIcon />
          Download
          {' '}
          {(invoice?.payment_status === 7) && (invoice?.is_proforma) ? 'Proforma' : 'Invoice'}
        </Box>
      </CardContent>
    </Item>
  );
};

export default DashboardInvoiceCard;
