import { RefreshRounded } from '@mui/icons-material';
import DashboardCustomizeIcon from '@mui/icons-material/DashboardCustomize';
import SearchIcon from '@mui/icons-material/Search';
import ViewListIcon from '@mui/icons-material/ViewList';
import ViewModuleIcon from '@mui/icons-material/ViewModule';
import {
  Box, Card,
  Chip, CircularProgress,
  Divider,
  Grid, InputAdornment, Stack,
  Tab,
  Tabs,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
  useMediaQuery
} from '@mui/material';
import { debounce } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Button from '../../../components/Button';
import EditableTable from '../../../components/EditableTable';
import TableFilterContainer from '../../../components/EditableTable/TableFilterContainer';
import { BoxIcon, StorageIcon } from '../../../components/Icons';
import { PRIORITY } from '../../../constans/logistics';
import { useAppDispatch } from '../../../hooks/toolkitHooks';
import {
  useGetAircraftListQuery, useGetOrdersQuery, useGetOrdersTableInternalQuery, useGetOrdersTableQuery
} from '../../../store/session';
import { useLocalStorage } from '../../../utils/hooks/useLocalStorage';
import { usePermissions } from '../../../utils/hooks/usePermissions';
import { stringifyLocationSearch } from '../../../utils/locationSearch';
import { btnS, headerBtn, headerBtnS } from '../../Invoices/style';
import {
  ORDER_STATUS, dashboardExternalAccess, dashboardInternalAccess, deliveryTypes,
  storageAccess
} from '../constants';
import {
  GreenButton,
  TextWithIcon,
  YellowButton
} from '../style';
import NewInvoice from './NewInvoice';
import { columns } from './columns';
import TaskCard from './components/TaskCard';
import TaskForm from './components/TaskForm';
import {
  GridRoot, StackStyle, StatusHeader, StatusText,
  boardsWrap
} from './styles';

const OrdersDashboard = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [locationOrderId, setLocationOrderId] = useState(location?.search?.split('=')?.[1] || null);
  const [form, setForm] = useState(false);
  const [order, setOrder] = useState(null);
  const [search, setSearch] = useState('');
  const [openInvoice, setOpenInvoice] = useState(false);
  const dispatch = useAppDispatch();
  const { getItem, setItem } = useLocalStorage();
  const dashBoardValue = getItem('viewDashboard');
  const [viewDashboard, setViewDashboard] = useState(dashBoardValue || 'module');
  const tabValue = location.pathname.split('/')[3];
  const [refreshing, setRefreshing] = useState(false);
  const [filterState, setFilterState] = useState({
    part_type: null,
    aircraft_id: null,
    storage: null,
    priority: null,
    status: null
  });
  const { data: internalOrders, isLoading: isLoadingInternalOrders, refetch: refetchInternal } = useGetOrdersTableInternalQuery(stringifyLocationSearch({
    type_display: 'board', search, ...filterState, ...(filterState.aircraft_id && { aircraft: filterState.aircraft_id })
  }), {
    refetchOnMountOrArgChange: true,
    skip: (viewDashboard !== 'module' && !location.search) || (tabValue === 'orders')
  });
  const { data: currentOrders, isLoading: isLoadingCurrentOrders, refetch: refetchCurrent } = useGetOrdersQuery(stringifyLocationSearch({
    type_display: 'board', search, ...filterState
  }), {
    refetchOnMountOrArgChange: true,
    skip: (viewDashboard !== 'module' && !location.search) || (tabValue !== 'orders')
  });

  const orders = (tabValue === 'orders') ? currentOrders : internalOrders;
  const isLoading = (tabValue === 'orders') ? isLoadingCurrentOrders : isLoadingInternalOrders;
  const refetch = (tabValue === 'orders') ? refetchCurrent : refetchInternal;
  const { data: AircraftList } = useGetAircraftListQuery();
  const maxWidth800px = useMediaQuery('(max-width:800px)');

  // Access
  const isLogist = usePermissions('logist');
  const isStorageAccess = usePermissions(storageAccess);
  const externalOrderCreator = usePermissions('logistics_order_creator');
  const externalStorageRead = usePermissions('logistics_storage_read');
  const isInternalLogist = usePermissions('internal_logist');
  const AOGPermission = usePermissions('logistics_order_read_aog');
  const AOGInternalPermission = usePermissions('internal_logistics_order_read_aog');

  const _internalAccess = usePermissions(dashboardInternalAccess);
  const _externalAccess = usePermissions(dashboardExternalAccess);

  const handleOpenOrder = (orderId, allOrders) => {
    const currentOrder = [
      ...allOrders?.data?.approved || [],
      ...allOrders?.data?.pending || [],
      ...allOrders?.data?.onTheWay || [],
      ...allOrders?.data?.new || []
    ].find(({ id }) => (id.toString() === orderId.toString()));

    setLocationOrderId(orderId);
    setOrder(currentOrder);
    setForm(true);
    navigate(location.pathname + stringifyLocationSearch({
      order: orderId
    }));
  };

  const handleCloseInvoice = () => {
    setOpenInvoice(false);
  };

  const handleRefresh = async () => {
    setRefreshing(true);
    if (viewDashboard === 'module') await refetch();
    else await new Promise(res => { setTimeout(res, 1000); });
    setRefreshing(false);
  };

  const handleCloseOrder = () => {
    setForm(false);
    navigate(location.pathname);
    setLocationOrderId(null);
    handleRefresh();
  };

  const handleTasksCount = (status) => {
    if (isLoading) {
      return (<CircularProgress size={20} />);
    }

    let count;
    switch (status) {
      case 'new':
        count = orders?.new_count ?? 0;
        break;
      case 'pending':
        count = orders?.pending_count ?? 0;
        break;
      case 'approved':
        count = orders?.approved_count ?? 0;
        break;
      case 'onTheWay':
        count = orders?.on_the_way_count ?? 0;
        break;
      default:
        count = 0;
    }

    return count ? (<Chip label={count} variant="outlined" />) : '';
  };

  const handleTasks = (data) => {
    if (isLoading) {
      return (<CircularProgress />);
    }

    if (data?.length) {
      return (
        data.map((task) => (
          <TaskCard key={task.id} task={task} openOrder={(id) => handleOpenOrder(id, orders)} />
        ))
      );
    }

    return (<Chip label="Empty" variant="outlined" />);
  };

  const aircraftOptions = [...(AircraftList || [])?.map((item) => ({
    value: item?.id,
    label: item?.aircraft_registration
  })),
  { value: 0, label: 'N/A' }
  ];

  const optionsSelectStorage = [
    { value: 'hof', label: 'Hof' },
    { value: 'budapest', label: 'Budapest' },
    ...(aircraftOptions || [])?.map(item => ({
      value: item?.label.toLowerCase(), label: item?.label
    }))
  ];

  const handleFilterState = (value, type) => {
    setFilterState(prev => ({
      ...prev,
      [type]: value
    }));
  };

  const clearFilterState = () => {
    setFilterState({
      aircraft_id: null,
      storage: null,
      priority: null,
      status: null
    });
  };

  const handleChangeView = (_, value) => {
    setViewDashboard(value);
    setItem('viewDashboard', value);
  };

  const handleChangeTab = (_, value) => {
    clearFilterState();
    navigate(`/logistics/dashboard/${value}`);
    // handleChangeView(null, 'module');
    handleRefresh();
  };

  const setPriorityList = (list = []) => {
    if (tabValue === 'orders') {
      if (AOGPermission) {
        return list;
      }

      return list.filter((item) => item?.value !== 10);
    }
    if (AOGInternalPermission) {
      return list;
    }

    return list.filter((item) => item?.value !== 10);
  };

  const filterConfig = {
    afterDivider: false,
    items: [
      {
        value: filterState.aircraft_id, callback: (e) => handleFilterState(e.target.value, 'aircraft_id'), closeCallback: () => handleFilterState(null, 'aircraft_id'), typeFilter: 'Select', icon: 'Airplane', options: aircraftOptions, placeholder: 'Select Aircraft'
      },
      ((tabValue === 'orders') && {
        value: filterState.storage, callback: (e) => handleFilterState(e.target.value, 'storage'), closeCallback: () => handleFilterState(null, 'storage'), typeFilter: 'Select', icon: 'Storage', options: optionsSelectStorage, placeholder: 'Select Storage'
      }),
      (tabValue !== 'orders' && {
        value: filterState.part_type, callback: (e) => handleFilterState(e.target.value, 'part_type'), closeCallback: () => handleFilterState(null, 'part_type'), typeFilter: 'Select', icon: 'ListStatus', options: deliveryTypes, placeholder: 'Select Order Type'
      }),
      {
        value: filterState.priority, callback: (e) => handleFilterState(e.target.value, 'priority'), closeCallback: () => handleFilterState(null, 'priority'), typeFilter: 'Select', icon: 'ListStatus', options: setPriorityList(PRIORITY), placeholder: 'Select Priority'
      },
      (viewDashboard === 'list' && {
        value: filterState.status, callback: (e) => handleFilterState(e.target.value, 'status'), closeCallback: () => handleFilterState(null, 'status'), typeFilter: 'Select', icon: 'ListStatus', options: ORDER_STATUS, placeholder: 'Select Status'
      })
    ]
  };

  useEffect(() => {
    handleChangeView(null, 'module');
  }, [location.pathname]);

  useEffect(() => {
    if (location.search) {
      const orderId = location.search.split('=')[1];

      if (orderId) {
        handleOpenOrder(orderId);
        setLocationOrderId(orderId);
      }
    }
  }, []);

  useEffect(() => {
    if (!_externalAccess && _internalAccess && !location.pathname.includes('delivery')) {
      navigate('/logistics/dashboard/delivery');
    }
  }, [location.pathname]);

  return (
    <>
      <Card
        sx={{
          mt: 3,
          padding: '1rem'
        }}
      >
        <Box sx={{
          ...headerBtn,
          ...(maxWidth800px && headerBtnS),
        }}
        >
          <Box sx={maxWidth800px ? { alignSelf: 'start' } : {}}>
            <Typography noWrap variant="h3" sx={{ ...TextWithIcon, textTransform: 'capitalize' }}>
              <DashboardCustomizeIcon />
              {tabValue === 'orders' ? 'External' : 'Internal'}
              {' '}
              Dashboard
            </Typography>
          </Box>

          <Box>
            <Tabs
              variant="fullWidth"
              centered
              value={tabValue}
              aria-label="basic tabs example"
              onChange={handleChangeTab}
              sx={{ mt: 0, pt: 0 }}
            >
              <Tab label="External" sx={{ fontSize: '1.5rem', mr: 5 }} value="orders" disabled={!_externalAccess} />
              <Tab label="Internal" sx={{ fontSize: '1.5rem' }} value="delivery" disabled={!_internalAccess} />
            </Tabs>
          </Box>

          <Box sx={{
            display: 'flex', gap: '10px', ...(maxWidth800px && { flexDirection: 'column', width: '100%', mb: '10px' }), ...(!maxWidth800px && { justifyContent: 'end', alignItems: 'center' })
          }}
          >
            {(isLogist || isInternalLogist) && (
            <Button
              startIcon={<BoxIcon />}
              sx={{ ...(!maxWidth800px && { ml: 2 }), ...(maxWidth800px && btnS), ...GreenButton }}
              title="Create Invoice"
              onClick={() => setOpenInvoice(true)}
            />
            )}
            <Button
              disabled={!isStorageAccess}
              startIcon={<StorageIcon />}
              onClick={() => {
                if (isStorageAccess) {
                  navigate('/logistics/orders-storage');
                }
              }}
              title="Storage"
            />
          </Box>
        </Box>
      </Card>

      <Card
        sx={{
          mt: 3,
          padding: '0 0.7rem 0.7rem 0.7rem'
        }}
      >
        <TableFilterContainer filterConfig={filterConfig} />
      </Card>

      <Card
        sx={{
          mt: 3,
          padding: '1rem'
        }}
      >
        <Box sx={maxWidth800px ? {
          display: 'flex',
          flexDirection: 'column',
          gap: 5
        } : {
          pb: 3,
          display: 'flex',
          flexWrap: 'wrap',
          alignItems: 'center',
          justifyContent: 'space-between'
        }}
        >
          <Box sx={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center' }}>
            <TextField
              size="small"
              autoComplete="off"
              type="text"
              sx={maxWidth800px ? { width: '100%' } : {
                minWidth: '300px', maxWidth: '300px'
              }}
              placeholder="Search this board"
              onChange={debounce((e) => setSearch(e.target.value), 400)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
          </Box>
          <Box sx={{
            display: 'flex', alignItems: 'center', ...(maxWidth800px && { justifyContent: 'space-between', mb: 5 }), columnGap: 4 
          }}
          >
            <ToggleButtonGroup
              value={viewDashboard}
              exclusive
              onChange={handleChangeView}
              size="small"
              aria-label="Small sizes"
              sx={{
                mr: 2,
                '& .Mui-selected': {
                  backgroundColor: '#dfe6f4'
                }
              }}
            >
              <ToggleButton value="module" aria-label="module">
                <ViewModuleIcon sx={{
                  color: 'rgba(76, 78, 100, 0.87)'
                }}
                />
              </ToggleButton>
              <ToggleButton value="list" aria-label="list">
                <ViewListIcon />
              </ToggleButton>
            </ToggleButtonGroup>
            <Button
              startIcon={<RefreshRounded />}
              sx={YellowButton}
              title="Refresh"
              onClick={handleRefresh}
              loading={refreshing}
            />
          </Box>
        </Box>
        {viewDashboard === 'list' ? (
          <EditableTable
            useHook={(tabValue === 'orders') ? useGetOrdersTableQuery : useGetOrdersTableInternalQuery}
            columns={columns}
            sortByType="DESC"
            sortByActive={(AOGPermission || AOGInternalPermission) ? 'priority' : 'part_name'}
            getRowHeight={() => 'auto'}
            getRowId={(row) => row.id}
            refetchTable={refreshing}
            handleOpenOrder={(id) => handleOpenOrder(id, orders)}
            dispatch={dispatch}
            dashboardType={tabValue}
            tableParams={{
              type_display: 'list',
              ...filterState,
              search
            }}
            style={{
              '& .MuiDataGrid-columnHeaders': { borderRadius: 0 }
            }}
          />
        )
          : (
            <Grid container sx={GridRoot}>
              <Grid container sx={{ ...boardsWrap, ...(maxWidth800px && { display: 'grid', gridTemplateColumns: '1fr 1fr 1fr 1fr' }) }} spacing={6}>
                <Grid item xs={3} sx={maxWidth800px ? { minWidth: '75vw' } : {}}>
                  <Card sx={{ backgroundColor: '#69c68b1a' }}>
                    <Box sx={StatusHeader}>
                      <Box>
                        <Typography noWrap variant="h6" sx={StatusText}>
                          New
                        </Typography>
                      </Box>
                      <Box>
                        {handleTasksCount('new')}
                      </Box>
                    </Box>
                    <Divider />
                    <Stack spacing={2} sx={StackStyle}>
                      {handleTasks(orders?.data?.new)}
                    </Stack>
                  </Card>
                </Grid>

                <Grid item xs={3} sx={maxWidth800px ? { minWidth: '75vw' } : {}}>
                  <Card sx={{ backgroundColor: '#ffff001f' }}>
                    <Box sx={StatusHeader}>
                      <Box>
                        <Typography noWrap variant="h6" sx={StatusText}>
                          Pending Approve
                        </Typography>
                      </Box>
                      <Box>
                        {handleTasksCount('pending')}
                      </Box>
                    </Box>
                    <Divider />
                    <Stack spacing={2} sx={StackStyle}>
                      {handleTasks(orders?.data?.pending)}
                    </Stack>
                  </Card>
                </Grid>

                <Grid item xs={3} sx={maxWidth800px ? { minWidth: '75vw' } : {}}>
                  <Card sx={{ backgroundColor: '#00ffff12' }}>
                    <Box sx={StatusHeader}>
                      <Box>
                        <Typography noWrap variant="h6" sx={StatusText}>
                          Approved
                        </Typography>
                      </Box>
                      <Box>
                        <Typography noWrap variant="h6" sx={StatusText}>
                          {handleTasksCount('approved')}
                        </Typography>
                      </Box>
                    </Box>
                    <Divider />
                    <Stack spacing={2} sx={StackStyle}>
                      {handleTasks(orders?.data?.approved)}
                    </Stack>
                  </Card>
                </Grid>

                <Grid item xs={3} sx={maxWidth800px ? { minWidth: '75vw' } : {}}>
                  <Card sx={{ backgroundColor: '#0000ff0a' }}>
                    <Box sx={StatusHeader}>
                      <Box>
                        <Typography noWrap variant="h6" sx={StatusText}>
                          On The Way
                        </Typography>
                      </Box>
                      <Box>
                        <Typography noWrap variant="h6" sx={StatusText}>
                          {handleTasksCount('onTheWay')}
                        </Typography>
                      </Box>
                    </Box>
                    <Divider />
                    <Stack spacing={2} sx={StackStyle}>
                      {handleTasks(orders?.data?.onTheWay)}
                    </Stack>
                  </Card>
                </Grid>
              </Grid>
            </Grid>
          )}
      </Card>

      <NewInvoice open={openInvoice} onClose={handleCloseInvoice} dashboardType={tabValue} />
      <TaskForm open={form} onClose={handleCloseOrder} currentOrder={order} refetchAllOrders={handleRefresh} locationOrderId={locationOrderId} aircraftOptions={aircraftOptions} />
    </>
  );
};

export default OrdersDashboard;
