import { Grid } from '@mui/material';
import React from 'react';
import Table from './list/Table';

const AircraftTypes = () => {
  const title = 'Aircraft Types';

  return (
    <Grid container spacing={6}>
      <Grid item xs={12}>
        
        <Table title={title} />
      </Grid>
    </Grid>
  );
};

export default AircraftTypes;
