import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { useProvideTags } from '../../utils/hooks/useProvideTags';
import { sessionApi } from '../session';

export const fetchInternalOrder = createAsyncThunk(
  'logistics/fetchInternalOrder',
  // eslint-disable-next-line consistent-return
  async ({ orderId }, { dispatch, rejectWithValue }) => {
    try {
      // eslint-disable-next-line no-use-before-define
      if (orderId) dispatch(setInternalOrder({ id: orderId }));
    } catch (error) {
      // Handle any errors appropriately
      return rejectWithValue(error.response.data);
    }
  }
);

// State and actions
export const internalLogisticsSlice = createSlice({
  name: 'internalLogistics',
  initialState: {
    internalOrder: {},
    orderRequests: [],
    dashboardType: 'orders',
    loading: false,
    error: null
  },
  reducers: {
    setInternalOrder: (state, action) => {
      state.order = action.payload;
    },
    setDashboardType: (state, action) => {
      state.dashboardType = action.payload;
    },
    updateExistInvoiceInOrder: (state, action) => {
      state.order.is_exist_invoice = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      sessionApi.endpoints.getInternalOrder.matchFulfilled,
      (state, { payload }) => {
        state.order = payload;
      }
    );
  },
});

// Logistics Endpoints
sessionApi.injectEndpoints({
  endpoints: (builder) => ({
    getInternalMyOrdersTable: builder.query({
      query: (data) => `/logistics/internal/my_orders${data}`,
      providesTags: (result, error) => useProvideTags(result, error, ['INTERNAL_ORDERS']),
    }),
    getInternalOrder: builder.query({
      query: ({ orderId }) => `/logistics/internal/order/${orderId}`,
      providesTags: (result, error) => useProvideTags(result, error, [{ type: 'INTERNAL_ORDER', id: result?.id }]),
    }),
    updateInternalOrderInfo: builder.mutation({
      query: ({ data, id }) => ({
        url: `/logistics/internal/order/${id}`,
        method: 'PATCH',
        body: data,
        headers: {
          Accept: 'application/json',
        },
      }),
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    sendStoreInternalOrder: builder.mutation({
      query: (data) => ({
        url: '/logistics/internal/order',
        method: 'POST',
        body: data,
        headers: {
          Accept: 'application/json',
        },
      }),
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    sendInternalRequestApprove: builder.mutation({
      query: ({ id, data }) => ({
        url: `/logistics/internal/order/request_approve/${id}`,
        method: 'POST',
        body: data,
        headers: {
          Accept: 'application/json',
        },
      }),
      providesTags: (result, error, arg) => useProvideTags(result, error, [{ type: 'INTERNAL_ORDER', id: arg?.[0]?.id }]),
      invalidatesTags: (result) => (result ? ['ALL_INTERNAL_ORDERS'] : []),
    }),
    internalDeclineOrder: builder.mutation({
      query: ({ id, data }) => ({
        url: `/logistics/internal/order/decline/${id}`,
        method: 'POST',
        body: data,
        headers: {
          Accept: 'application/json',
        },
      }),
      providesTags: (result, error) => useProvideTags(result, error),
      invalidatesTags: (result) => (result ? ['ALL_INTERNAL_ORDERS', 'INTERNAL_ORDER'] : []),
    }),
    sendInternalApprove: builder.mutation({
      query: ({ id, data }) => ({
        url: `/logistics/internal/order/approve/${id}`,
        method: 'POST',
        body: data,
        headers: {
          Accept: 'application/json',
        },
      }),
      providesTags: (result, error, arg) => useProvideTags(result, error, [{ type: 'INTERNAL_ORDER', id: arg?.[0]?.id }]),
      invalidatesTags: (result) => (result ? ['ALL_INTERNAL_ORDERS'] : []),
    }),
    sendInternalComplete: builder.mutation({
      query: ({ id, data }) => ({
        url: `/logistics/internal/order/complete/${id}`,
        method: 'POST',
        body: data,
        headers: {
          Accept: 'application/json',
        },
      }),
      providesTags: (result, error, arg) => useProvideTags(result, error, [{ type: 'INTERNAL_ORDER', id: arg?.[0]?.id }]),
      invalidatesTags: (result) => (result ? ['ALL_INTERNAL_ORDERS'] : []),
    }),
    sendInternalDelivered: builder.mutation({
      query: ({ id, data }) => ({
        url: `/logistics/internal/order/delivered/${id}`,
        method: 'POST',
        body: data,
        cache: 'no-cache',
        // headers: {
        //   Accept: 'application/json',
        // },
      }),
      providesTags: (result, error, arg) => useProvideTags(result, error, [{ type: 'INTERNAL_ORDER', id: arg?.[0]?.id }]),
      invalidatesTags: (result) => (result ? ['ALL_INTERNAL_ORDERS'] : []),
    }),
    deleteInternalOrder: builder.mutation({
      query: (id) => ({
        url: `/logistics/internal/order/${id}`,
        method: 'DELETE',
        headers: {
          Accept: 'application/json',
        },
      }),
      invalidatesTags: (result) => (result ? ['INTERNAL_ORDERS'] : []),
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    updateInternalOrder: builder.mutation({
      query: ({ id, data }) => ({
        url: `/logistics/internal/order/${id}`,
        method: 'PATCH',
        body: data,
        headers: {
          Accept: 'application/json',
        },
      }),
      invalidatesTags: (result) => (result ? ['INTERNAL_ORDERS'] : []),
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    getInternalAutocompleteParts: builder.query({
      query: ({
        search, search_row, id_with_sa, is_part_on
      }) => `/logistics/internal/autocomplete/parts?search=${search}${search_row ? `&search_row=${search_row}` : ''}${id_with_sa ? '&id_with_sa=true' : ''}${is_part_on ? '&is_part_on=true' : ''}`,
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    getInternalAutocompleteOrders: builder.query({
      query: ({ search }) => `/logistics/internal/autocomplete/orders?search=${search}`,
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    getInternalPaymentRequests: builder.query({
      query: ( { payment_type, orderId } ) => `/logistics/internal/payment_requests/${orderId}?payment_type=${payment_type}`,
      providesTags: (result, error) => useProvideTags(result, error, ['ALL_INTERNAL_ORDERS', 'INTERNAL_ORDER', 'INVOICE_REQUEST_SEND']),
      keepUnusedDataFor: 0, // Disable caching
    }),
    getDeliveryOrdersList: builder.query({
      query: ({ variant }) => `/logistics/internal/autocomplete/invoice/orders?invoice_type=${variant}`,
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    updateInternalAircraft: builder.mutation({
      query: ({ id, data }) => {
        let newData = { ...data };

        if (data?.aircraft_reservation === 0) {
          newData = {
            ...data,
            aircraft_reservation: null
          };
        }

        return {
          url: `/logistics/internal/order/update_aircraft/${id}`,
          method: 'PATCH',
          body: newData,
          headers: {
            Accept: 'application/json',
          },
        };
      },
    }),
    getInternalOrderAllInfo: builder.query({
      query: ({ id }) => `/logistics/internal/order/get_info/${id}`,
      invalidatesTags: (result) => (result ? ['INTERNAL_ORDER_ALL_INFO'] : []),
      providesTags: (result, error) => useProvideTags(result, error, [{ type: 'EXTERNAL_ORDER_ALL_INFO', id: result?.main?.order_number }]),
    }),
    toggleInternalOrderCompleted: builder.mutation({
      query: ({ id }) => ({
        url: `/logistics/internal/order/toggle/complete/${id}`,
        method: 'POST',
        headers: {
          Accept: 'application/json',
        },
      }),
      providesTags: (result, error) => useProvideTags(result, error),
    }),
  }),
});

export const {
  useGetInternalMyOrdersTableQuery,
  useGetInternalOrderQuery,
  useUpdateInternalOrderInfoMutation,
  useSendStoreInternalOrderMutation,
  useSendInternalRequestApproveMutation,
  useInternalDeclineOrderMutation,
  useSendInternalApproveMutation,
  useSendInternalCompleteMutation,
  useSendInternalDeliveredMutation,
  useDeleteInternalOrderMutation,
  useUpdateInternalOrderMutation,
  useGetInternalAutocompletePartsQuery,
  useGetInternalAutocompleteOrdersQuery,
  useGetInternalPaymentRequestsQuery,
  useGetDeliveryOrdersListQuery,
  useUpdateInternalAircraftMutation,
  useGetInternalOrderAllInfoQuery,
  useToggleInternalOrderCompletedMutation
} = sessionApi;

export const {
  setDashboardType, setInternalOrder, updateExistInvoiceInOrder
} = internalLogisticsSlice.actions;

export default internalLogisticsSlice.reducer;
