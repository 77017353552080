import {
  CardContent,
  Grid, Typography
} from '@mui/material';
import { styled } from '@mui/material/styles';
import React from 'react';

const HeaderTypography = styled(Typography)({
  fontWeight: 'bold',
  color: '#333',
});

const DocsTableHeader = () => (
  <CardContent sx={{
    margin: '0',
    boxShadow: '0 3px 5px 2px rgba(0, 0, 0, 0.1)',
    backgroundColor: '#f5f5f5',
    borderRadius: '4px 0px',
  }}
  >
    <Grid container alignItems="center" spacing={2}>
      <Grid item xs={3}>
        <HeaderTypography variant="body1">File Name</HeaderTypography>
      </Grid>
      <Grid item xs={3}>
        <HeaderTypography variant="body1">Status</HeaderTypography>
      </Grid>
      <Grid item xs={3}>
        <HeaderTypography variant="body1">Created At</HeaderTypography>
      </Grid>
      <Grid item xs={3}>
        <HeaderTypography variant="body1" sx={{ textAlign: 'end' }}>Sender</HeaderTypography>
      </Grid>
    </Grid>
  </CardContent>
);

export default DocsTableHeader;
