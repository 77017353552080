import {
  object,
  string
} from 'yup';
import { externalOrdersType } from '../../../../constants';

export const defaultValues = {
  ttm_code: '',
  delivery_operator_id: '',
  delivery_due_date: '',
  isRequiredDueDate: false,
  estimated_date: '',
  estimated_delivery_price: 0,
  estimated_delivery_currency_id: 11
};

export const schema = object().shape({
  ttm_code: string()
    .required()
    .label('TTM Code'),
  delivery_operator_id: string()
    .required()
    .label('Delivery operator'),
  delivery_due_date: string()
    .when('isRequiredDueDate', {
      is: (val) => val,
      then: string().required('${label} cannot be blank.'),
      otherwise: string().nullable()
    })
    .typeError('${label} cannot be blank.')
    .label('Delivery Due Date'),
  estimated_date: string().when('orderType', {
    is: (val) => [...externalOrdersType, 'Repair_out', 'Overhaul_out'].includes(val),
    then: string().required('${label} cannot be blank.'),
    otherwise: string().nullable()
  }).label('Estimated Delivery Date'),
  estimated_delivery_price: string()
    .when('orderType', {
      is: (val) => [...externalOrdersType, 'Repair_out', 'Overhaul_out'].includes(val),
      then: string().required('${label} cannot be blank.'),
      otherwise: string().nullable()
    })
    .label('Estimated Delivery Price'),
  estimated_delivery_currency_id: string()
    .when('orderType', {
      is: (val) => [...externalOrdersType, 'Repair_out', 'Overhaul_out'].includes(val),
      then: string().required('${label} cannot be blank.'),
      otherwise: string().nullable()
    })
    .label('Estimated Delivery Currency'),
});
