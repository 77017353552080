import { yupResolver } from '@hookform/resolvers/yup';
import {
  Box,
  Card, CardContent, Divider, Grid, InputAdornment, TextField,
  Typography
} from '@mui/material';
import { Plus, Rename } from 'mdi-material-ui';
import React, { useEffect, useState } from 'react';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import Button from '../../../../components/Button';
import Drawer from '../../../../components/Drawer';
import { CloseIcon } from '../../../../components/Icons';
import Autocomplete from '../../../../form/components/Autocomplete/Autocomplete';
import Checkbox from '../../../../form/components/Checkbox';
import DatePicker from '../../../../form/components/DatePicker';
import FormGroup from '../../../../form/components/FormGroup';
import Input from '../../../../form/components/Input';
import Select from '../../../../form/components/Select';
import ServerAutocomplete from '../../../../form/components/ServerAutocomplete';
import { useCreateStoreFromPartMutation, useSearchSupplierQuery } from '../../../../store/slices/logisticsSlice';
import { setDateValue, validateDatePickerValue } from '../../../../utils/setDateValue';
import { btnS } from '../../../Invoices/style';
import { content } from '../../MyOrders/NewOrder/components/style';
import {
  Filters, GreenButton, RedButton
} from '../../style';
import { defaultValues, schema } from './schema';

const PriceDrawer = (props) => {
  const {
    open = false,
    onClose = () => {},
    currencies,
    handleRefresh = () => {},
    orderId = null,
    aircraftOptions = []
  } = props;

  // State
  const [supplier, setSupplier] = useState({});

  // API
  const [sendOrder, { isLoading: isCreatingOrder }] = useCreateStoreFromPartMutation();

  const {
    reset,
    setValue,
    control,
    watch,
    handleSubmit,
    formState: { errors },
    trigger,
    getValues
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(schema),
    defaultValues,
  });

  const addressSelectOptions = [
    { label: 'Hof', value: 'hof' },
    { label: 'Budapest', value: 'budapest' },
    ...aircraftOptions
  ].filter(({ label }) => label !== 'N/A');

  const prices = useFieldArray({
    name: 'prices',
    control
  });

  const handleChangeAddress = (data, key) => {
    if (data === 'hof') {
      setValue(
        key,
        'Fleet Air International Kft\nPirk 20 Flughafen Hof-Plauen GmbH & Co. KG\nHOF, SAALE\n95032\nGermany'
      );
    } else if (data === 'budapest') {
      setValue(
        key,
        'Fleet Air International Kft\n2220 Vecses\nFo ut 218\nHungary'
      );
    } else {
      setValue(key, addressSelectOptions.find(({ value }) => data === value)?.label);
    }
  };

  const onSubmit = async (data) => {
    const toastId = toast.loading('Loading...');
    const sendData = {
      ...data,
      prices: (data?.prices || [])?.map(pr => {
        delete pr?.from;

        return pr;
      })
    };

    const res = await sendOrder({ data: sendData, id: orderId });

    if (res?.error?.data) {
      toast.error(res?.error?.data?.message || 'Something went wrong', {
        id: toastId,
      });

      return;
    }

    onClose();
    handleRefresh();

    toast.success('Successfully!', {
      id: toastId,
    });
  };

  useEffect(() => {
    if (open) {
      reset();
      setSupplier({});
    }
  }, [open]);

  return (
    <Drawer
      open={open}
      onClose={onClose}
      title={`Order #${orderId}`}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid
          container
          spacing={2}
          rowSpacing={2}
        >
          <Grid item xs={12}>
            <Card>
              <CardContent>
                <Box sx={content}>

                  {prices.fields.map((item, index) => (
                    <Grid
                      container
                      sx={{
                      // display: 'flex',
                        backgroundColor: 'white',
                        padding: '15px',
                        borderRadius: '8px'
                      }}
                      spacing={2}
                      key={item.id}
                    >
                      <Grid item xs={12} lg={12}>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                          <Box>
                            <Typography noWrap variant="h6">
                              Price #
                              {index + 1}
                            </Typography>
                          </Box>

                          {index !== 0 && (
                          <Button
                            sx={{ ...(RedButton), fontWeight: 600 }}
                            endIcon={<CloseIcon />}
                            size="small"
                            title="Delete"
                            onClick={() => {
                              prices.remove(index);
                              setSupplier(prev => {
                                const a = { ...prev };
                                delete a[index];

                                return a;
                              });
                            }}
                          />
                          )}
                        </Box>
                      </Grid>

                      <Grid item xs={4}>
                        <FormGroup label="Amount" required hasError={errors?.prices?.[index]?.amount}>
                          <Controller
                            name={`prices.${[index]}.amount`}
                            control={control}
                            render={({ field }) => (
                              <TextField
                                {...field}
                                size="small"
                                type="number"
                                placeholder="Amount"
                                sx={{ width: '100%' }}
                                InputProps={{
                                  startAdornment: (
                                    <InputAdornment position="start">
                                      <Rename sx={{ ...(field?.value && { color: '#626477' }) }} />
                                    </InputAdornment>
                                  ),
                                }}
                                onChange={(e) => {
                                  if (+e?.target?.value > 999999999) return;
                                  field.onChange(+e?.target?.value || '');
                                }}
                              />
                            )}
                          />
                        </FormGroup>
                      </Grid>

                      <Grid item xs={4}>
                        <FormGroup label="Currency" required hasError={!!errors.prices?.[index]?.currency_id}>
                          <Controller
                            control={control}
                            name={`prices.${[index]}.currency_id`}
                            render={({ field }) => (
                              <Autocomplete
                                {...field}
                                value={field.value ? currencies?.filter(cur => ((cur?.value === field?.value) || (cur?.value === field?.value?.value)))[0] : null}
                                options={currencies}
                                size="small"
                                placeholder="Please select a currency"
                                onChange={(e, value ) => {
                                  field.onChange(value?.value);
                                }}
                              />
                            )}
                          />
                        </FormGroup>
                      </Grid>

                      <Grid item xs={4}>
                        <FormGroup label="Estimated Date Return Repair" required hasError={!!errors.prices?.[index]?.estimated_date}>
                          <Controller
                            control={control}
                            name={`prices.${[index]}.estimated_date`}
                            render={({ field }) => (
                              <DatePicker
                                placeholderText="YYYY-MM-DD"
                                dateFormat="yyyy-MM-dd"
                                showMonthDropdown
                                showYearDropdown
                                {...field}
                                onChange={(e) => {
                                  field.onChange(e);
                                  setDateValue(e, setValue, `prices.${[index]}.estimated_date`, 'YYYY-MM-DD');
                                }}
                                value={validateDatePickerValue(field.value)}
                              />
                            )}
                          />
                        </FormGroup>
                      </Grid>

                      <Grid item xs={4}>
                        <FormGroup label="Estimated Delivery Price" hasError={!!errors.prices?.[index]?.estimated_delivery_price}>
                          <Controller
                            control={control}
                            name={`prices.${[index]}.estimated_delivery_price`}
                            render={({ field }) => (
                              <TextField
                                {...field}
                                size="small"
                                type="number"
                                placeholder="Estimated Delivery Price"
                                sx={{ width: '100%' }}
                                InputProps={{
                                  startAdornment: (
                                    <InputAdornment position="start">
                                      <Rename sx={{ ...(field?.value && { color: '#626477' }) }} />
                                    </InputAdornment>
                                  ),
                                }}
                                onBlur={(e) => {
                                  if (+e?.target?.value <= 0) {
                                    field.onChange(1);
                                  }
                                }}
                                onChange={(e) => {
                                  if (+e?.target?.value > 999999999) return;
                                  
                                  field.onChange(+e?.target?.value || '');
                                }}
                              />
                            )}
                          />
                        </FormGroup>
                      </Grid>

                      <Grid item xs={4}>
                        <FormGroup label="Estimated Delivery Currency" hasError={!!errors.prices?.[index]?.estimated_delivery_currency_id}>
                          <Controller
                            control={control}
                            name={`prices.${[index]}.estimated_delivery_currency_id`}
                            render={({ field }) => (
                              <Autocomplete
                                {...field}
                                value={field.value ? currencies?.filter(cur => (+cur?.value === +field?.value))?.[0] : null}
                                options={currencies}
                                size="small"
                                placeholder="Please select a Estimated Delivery Currency"
                                onChange={(e, value ) => {
                                  field.onChange(+value?.value);
                                }}
                              />
                            )}
                          />
                        </FormGroup>
                      </Grid>

                      {/* Address Form */}

                      <Grid
                        container
                        spacing={2}
                        sx={{
                          margin: '0',
                        }}
                      >
                        <Grid item xs={5}>
                          <FormGroup label="From">
                            <Controller
                              name={`prices.${[index]}.from`}
                              control={control}
                              render={({ field }) => (
                                <Select
                                  options={addressSelectOptions}
                                  placeholder="From"
                                  {...field}
                                  onChange={(e) => {
                                    handleChangeAddress(e?.target?.value, `prices.${[index]}.address_from`);
                                    field?.onChange(e?.target?.value);
                                  }}
                                />
                              )}
                            />
                          </FormGroup>
                        </Grid>
                        <Grid item xs={7}>
                          <FormGroup label="From Address" required hasError={errors?.prices?.[index]?.address_from}>
                            <Controller
                              name={`prices.${[index]}.address_from`}
                              control={control}
                              render={({ field }) => (
                                <Input
                                  {...field}
                                  multiline
                                  sx={{ width: '100%' }}
                                />
                              )}
                            />
                          </FormGroup>
                        </Grid>
                        <Grid item xs={5}>
                          <FormGroup label="Address To Supplier">
                            <Controller
                              name={`prices.${[index]}.address_to_supplier_id`}
                              control={control}
                              render={({ field }) => (
                                <ServerAutocomplete
                                  field={field}
                                  value={supplier[index]}
                                  withData
                                  placeholder="Search suppliers by name or email"
                                  searchQueryFn={useSearchSupplierQuery}
                                  queryParams={{ type: 'service' }}
                                  handleSelect={(value) => {
                                    field.onChange(value?.value);
                                    setSupplier({ ...supplier, [index]: value });
                                    setValue(`prices.${[index]}.address_to`, value?.data?.actual_address || value?.data?.label);
                                    setValue(`prices.${[index]}.address_to_supplier_id`, value?.data?.id);
                                  }}
                                />
                              )}
                            />
                          </FormGroup>
                        </Grid>
                        <Grid item xs={7}>
                          <FormGroup label="To Address" required hasError={errors?.prices?.[index]?.address_to}>
                            <Controller
                              name={`prices.${[index]}.address_to`}
                              control={control}
                              render={({ field }) => (
                                <Input
                                  {...field}
                                  multiline
                                  sx={{ width: '100%' }}
                                />
                              )}
                            />
                          </FormGroup>
                        </Grid>
                      </Grid>

                      <Grid item xs={6} lg={6}>
                        <FormGroup label="Suggested Price" required hasError={!!errors.prices?.[index]?.suggested}>
                          <Controller
                            name={`prices.${[index]}.suggested`}
                            control={control}
                            render={({ field }) => (
                              <Checkbox
                                {...field}
                              />
                            )}
                          />
                        </FormGroup>
                      </Grid>
                    </Grid>
                  ))}
                </Box>

                <Grid container sx={{ mt: 2.75, mb: 2.75, justifyContent: 'center' }}>
                  <Grid item xs={4} sx={{ px: 0 }}>
                    <Button
                      title="Add Price"
                      startIcon={<Plus />}
                      sx={{ ...btnS, ...GreenButton }}
                      size="small"
                      onClick={() => prices.append({
                        ...defaultValues.prices[0]
                      })}
                    />
                  </Grid>
                </Grid>
              </CardContent>
            </Card>

          </Grid>

          <Grid item xs={12}>
            <Card>
              <CardContent>
                <Divider />
                <Box sx={{ ...(Filters), padding: '0px', justifyContent: 'space-between' }}>
                  <Box>
                    <Button
                      sx={{ mt: 2, ...(RedButton) }}
                      variant="contained"
                      size="large"
                      title="Cancel"
                      onClick={onClose}
                    />
                  </Box>
                  <Box>
                    <Button
                      sx={{ mt: 2, ...(GreenButton) }}
                      variant="contained"
                      size="large"
                      disabled={isCreatingOrder}
                      onClick={handleSubmit(onSubmit)}
                      title="Submit"
                    />
                  </Box>
                </Box>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </form>
    </Drawer>
  );
};

export default PriceDrawer;
