import { object, string } from 'yup';

export const defaultValues = {
  aircraft_type_name: '',
  aircraft_display_name: '',
};

export const schema = object().shape({
  aircraft_type_name: string()
    .required('${label} cannot be blank.')
    .label('Aircraft Type Name'),
  aircraft_display_name: string()
    .required('${label} cannot be blank.')
    .label('Aircraft Display Name'),
});