import CreditCardOffIcon from '@mui/icons-material/CreditCardOff';
import CreditScoreIcon from '@mui/icons-material/CreditScore';
import PollIcon from '@mui/icons-material/Poll';
import {
  Box, Card,
  Grid,
  Tab,
  Tabs
} from '@mui/material';
import Chip from '@mui/material/Chip';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import moment from 'moment';
import React, { useState } from 'react';
import Button from '../../../components/Button';
import EditableTable from '../../../components/EditableTable';
import TableFilterContainer from '../../../components/EditableTable/TableFilterContainer';
import {
  useGetExternalLogisticStatisticsTableQuery,
  useGetInternalLogisticStatisticsTableQuery,
  useGetLogisticStatisticsTotalQuery
} from '../../../store/slices/financeSlice';
import { stringifyLocationSearch } from '../../../utils/locationSearch';
import { validateDatePickerValueCopy } from '../../../utils/setDateValue';
import CardStatistics from '../components/CardStatistics';
import { columns } from './columns';
import { EXTERNAL_TAB, INTERNAL_TAB } from './constants';
import InvoicesDrawer from './InvoicesDrawer';

const LogisticStatistics = () => {
  const [dateFromFilter, setDateFromFilter] = useState(null);
  const [dateFrom, setDateFrom] = useState(null);
  const [dateToFilter, setDateToFilter] = useState(null);
  const [dateTo, setDateTo] = useState(null);
  const [statusFilter, setStatusFilter] = useState(null);
  const [typeFilter, setTypeFilter] = useState(null);
  const [search, setSearch] = useState(null);
  const [activeTab, setActiveTab] = useState('external');
  const [refresh, setRefresh] = useState(0);
  const [activeOrderId, setActiveOrderId] = useState(null);

  const { data: dataTotal } = useGetLogisticStatisticsTotalQuery(stringifyLocationSearch({
    status: statusFilter,
    order_type: typeFilter,
    delivery_date_from: dateFromFilter,
    delivery_date_to: dateToFilter,
    search,
  }), {
    refetchOnMountOrArgChange: true
  });

  const filterConfig = {
    items: [
      {
        value: search,
        callback: (e) => setSearch(e.target.value),
        // callback: debounce((e) => setSearch(e.target.value), 400),
        closeCallback: () => setSearch(null),
        typeFilter: 'TextField',
        icon: 'Search',
        placeholder: 'Search'
      },
      {
        value: statusFilter,
        callback: (e) => setStatusFilter(e.target.value),
        closeCallback: () => setStatusFilter(null),
        typeFilter: 'Select',
        icon: 'ListStatus',
        options: [
          { label: '', value: '2', icon: (<Chip label="APPROVED" size="small" color="info" sx={{ fontWeight: 'bolder' }} />) },
          { label: '', value: '3', icon: (<Chip label="ON THE WAY" size="small" color="primary" sx={{ fontWeight: 'bolder' }} />) },
          { label: '', value: '4', icon: (<Chip label="DELIVERED" size="small" color="success" sx={{ fontWeight: 'bolder' }} />) },
          { label: '', value: '7', icon: (<Chip label="DELIVERED" size="small" sx={{ fontWeight: 'bolder', backgroundColor: '#2240fa', color: '#ffffff' }} />) },
          { label: '', value: '6', icon: (<Chip label="INSTALLED" size="small" sx={{ fontWeight: 'bolder', backgroundColor: '#2240fa', color: '#ffffff' }} />) },
        ],
        placeholder: 'Select Status'
      },
      {
        value: typeFilter,
        callback: (e) => setTypeFilter(e.target.value),
        closeCallback: () => setTypeFilter(null),
        typeFilter: 'Select',
        icon: 'ListStatus',
        options: [
          { label: 'Outright', value: 'outright' },
          { label: 'Exchange', value: 'exchange' },
          { label: 'Repair in', value: 'Repair_in' },
          { label: 'Overhaul in', value: 'Overhaul_in' },
        ],
        placeholder: 'Select Type'
      },
      {
        value: validateDatePickerValueCopy(dateFrom || null),
        callback: (e) => {
          setDateFrom(validateDatePickerValueCopy(e));
          setDateFromFilter(e ? moment(e).format('DD-MM-YYYY') : '');
        },
        closeCallback: () => {
          setDateFrom(null);
          setDateFromFilter(null);
        },
        typeFilter: 'DatePicker',
        icon: 'CalendarMonthIcon',
        placeholder: 'Date from'
      },
      {
        value: validateDatePickerValueCopy(dateTo || null),
        callback: (e) => {
          setDateTo(validateDatePickerValueCopy(e));
          setDateToFilter(e ? moment(e).format('DD-MM-YYYY') : '');
        },
        closeCallback: () => {
          setDateTo(null);
          setDateToFilter(null);
        },
        typeFilter: 'DatePicker',
        icon: 'CalendarMonthIcon',
        placeholder: 'Date to'
      },
    ]
  };
  const handleRefresh = () => {
    setRefresh(!refresh);
  };
  const handleChangeTab = (_, value) => {
    setActiveTab(value);
    handleRefresh();
  };
  const handleInvoicesDrawer = (value) => {
    setActiveOrderId(value);
  };

  const setDateFilter = (value) => {
    let dateFrom = null;

    if (value === 'week') {
      dateFrom = moment().subtract(7, 'days');
    }
    if (value === 'month') {
      dateFrom = moment().subtract(31, 'days');
    }
    if (value === 'year') {
      dateFrom = moment().subtract(365, 'days');
    }

    if (dateFrom) {
      setDateFrom(validateDatePickerValueCopy(dateFrom));
      setDateFromFilter(dateFrom.format('DD-MM-YYYY'));
      const dateTo = moment();
      setDateTo(validateDatePickerValueCopy(dateTo));
      setDateToFilter(dateTo.format('DD-MM-YYYY'));
    }
  };

  return (
    <Grid container spacing={6}>
      <Grid item xs={12}>
        {dataTotal && (
          <Grid container spacing={6}>
            <Tooltip
              placement="bottom-start"
              variant="soft"
              title={(
                <Box>
                  {dataTotal.total_info?.total
                    && Object.entries(dataTotal.total_info.total).map(([key, value]) => (
                      <Typography key={key} sx={{ color: '#fff', textTransform: 'capitalize' }}>
                        {key.replace('_', ' ')}
                        {': '}
                        {value}
                        {' '}
                        EUR
                      </Typography>
                    ))}
                </Box>
              )}
            >
              <Grid item xs={12} md={3} sm={6}>
                <CardStatistics title="Total" icon={<PollIcon />} stats={dataTotal.total} color="info" />
              </Grid>
            </Tooltip>
            <Tooltip
              placement="bottom-start"
              variant="soft"
              title={(
                <Box>
                  {dataTotal.total_info?.paid
                    && Object.entries(dataTotal.total_info.paid).map(([key, value]) => (
                      <Typography key={key} sx={{ color: '#fff', textTransform: 'capitalize' }}>
                        {key.replace('_', ' ')}
                        {': '}
                        {value}
                        {' '}
                        EUR
                      </Typography>
                    ))}
                </Box>
              )}
            >
              <Grid item xs={12} md={3} sm={6}>
                <CardStatistics title="Paid" icon={<CreditScoreIcon />} stats={dataTotal.paid} />
              </Grid>
            </Tooltip>
            <Tooltip
              placement="bottom-start"
              variant="soft"
              title={(
                <Box>
                  {dataTotal.total_info?.estimated_unpaid
                    && Object.entries(dataTotal.total_info.estimated_unpaid).map(([key, value]) => (
                      <Typography key={key} sx={{ color: '#fff', textTransform: 'capitalize' }}>
                        {key.replace('_', ' ')}
                        {': '}
                        {value}
                        {' '}
                        EUR
                      </Typography>
                    ))}
                </Box>
              )}
            >
              <Grid item xs={12} md={3} sm={6}>
                <CardStatistics title="Estimated unpaid" icon={<CreditCardOffIcon />} stats={dataTotal.estimated_unpaid} color="error" />
              </Grid>
            </Tooltip>
            <Tooltip
              placement="bottom-start"
              variant="soft"
              title={(
                <Box>
                  {dataTotal.total_info?.invoice_unpaid
                    && Object.entries(dataTotal.total_info.invoice_unpaid).map(([key, value]) => (
                      <Typography key={key} sx={{ color: '#fff', textTransform: 'capitalize' }}>
                        {key.replace('_', ' ')}
                        {': '}
                        {value}
                        {' '}
                        EUR
                      </Typography>
                    ))}
                </Box>
              )}
            >
              <Grid item xs={12} md={3} sm={6}>
                <CardStatistics title="Invoice unpaid" icon={<CreditCardOffIcon />} stats={dataTotal.invoice_unpaid} color="error" />
              </Grid>
            </Tooltip>
          </Grid>
        )}
      </Grid>

      <Grid item xs={12}>
        <Box>
          <Tabs
            centered
            value={activeTab}
            aria-label="basic tabs example"
            onChange={handleChangeTab}
            sx={{ mt: 0, pt: 0 }}
          >
            <Tab label="External" sx={{ fontSize: '1.5rem', mr: 5 }} value={EXTERNAL_TAB} />
            <Tab label="Internal" sx={{ fontSize: '1.5rem' }} value={INTERNAL_TAB} />
          </Tabs>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Card>
          <TableFilterContainer filterConfig={filterConfig} />
          <Box sx={{
            p: 3, display: 'flex', flexWrap: 'wrap', alignItems: 'center'
          }}
          >
            <Button sx={{ mr: 3 }} onClick={() => setDateFilter('week')}>Last Week</Button>
            <Button sx={{ mr: 3 }} onClick={() => setDateFilter('month')}>Last Month</Button>
            <Button onClick={() => setDateFilter('year')}>Last Year</Button>
          </Box>

          <EditableTable
            useHook={activeTab === EXTERNAL_TAB ? useGetExternalLogisticStatisticsTableQuery : useGetInternalLogisticStatisticsTableQuery}
            columns={columns}
            sortByType="DESC"
            sortByActive="id"
            getRowHeight={() => 'auto'}
            tableParams={{
              status: statusFilter,
              order_type: typeFilter,
              delivery_date_from: dateFromFilter,
              delivery_date_to: dateToFilter,
              search,
              refresh
            }}
            style={{
              '& .MuiDataGrid-columnHeaders': { borderRadius: 0 }
            }}
            handleInvoicesDrawer={handleInvoicesDrawer}
          />
        </Card>
      </Grid>
      <InvoicesDrawer onClose={() => handleInvoicesDrawer(null)} orderId={activeOrderId} orderType={activeTab} refetchTable={handleRefresh} />
    </Grid>
  );
};

export default LogisticStatistics;
