import { Box, Typography } from '@mui/material';
import React from 'react';
import Drawer from '../../../../components/Drawer';
import { useGetExternalOrderInvoicesQuery, useGetInternalOrderInvoicesQuery } from '../../../../store/slices/financeSlice';
import InvoiceCard from '../../../Logistics/OrdersDashboard/components/forms/approved/invoices/InvoiceCard';
import InvoiceTableHeader from '../../../Logistics/OrdersDashboard/components/forms/approved/invoices/InvoiceTableHeader';
import { EXTERNAL_TAB, INTERNAL_TAB } from '../constants';

const InvoicesDrawer = ({
  onClose, orderId, orderType, refetchTable = () => {}
}) => {
  const { data: internalInvoicesData, refetch: refetchInternalInvoices } = useGetInternalOrderInvoicesQuery(orderId, {
    refetchOnMountOrArgChange: true,
    skip: (orderType === EXTERNAL_TAB || !orderId)
  });
  const { data: externalInvoicesData, refetch: refetchExternalInvoices } = useGetExternalOrderInvoicesQuery(orderId, {
    refetchOnMountOrArgChange: true,
    skip: (orderType === INTERNAL_TAB || !orderId)
  });

  const invoicesData = orderType === EXTERNAL_TAB ? externalInvoicesData : internalInvoicesData;
  const refetchInvoices = orderType === EXTERNAL_TAB ? refetchExternalInvoices : refetchInternalInvoices;

  const handleDeleteCallback = () => {
    refetchInvoices();
    refetchTable();
  };

  return (
    <Drawer
      title={`Invoices #${orderId}`}
      open={!!orderId}
      onClose={onClose}
    >
      <Box p={3}>
        {invoicesData && Object.values(invoicesData)?.length ? (
          <>
            <InvoiceTableHeader />
            {Object.values(invoicesData)?.map((element) => (
              <InvoiceCard key={element.id} invoice={element} deleteCallback={handleDeleteCallback} />
            ))}
          </>
        ) : (
          <Box sx={{
            display: 'flex', flexDirection: 'column', alignItems: 'center', justifyItems: 'center'
          }}
          >
            <Typography variant="h5" noWrap>
              No related invoices
            </Typography>
          </Box>
        )}
      </Box>
    </Drawer>
  );
};

export default InvoicesDrawer;
