import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { useForm } from 'react-hook-form';
import { toast } from 'react-hot-toast';
import { useSendInternalDeliveredMutation, useUpdateInternalOrderMutation } from '../../../../../../store/slices/internalLogisticsSlice';
import { useSendDeliveredMutation, useUpdateOrderMutation } from '../../../../../../store/slices/logisticsSlice';
import {
  TTMschema,
  defaultTTMValues,
  defaultValues,
  internalSchema,
  schema
} from './schema';

export const useOnTheWayForm = ({
  order, onClose, refetch, dashboardType
}) => {
  const [sendCurrent, isLoadingCurrent] = useSendDeliveredMutation();
  const [sendInternal, isLoadingInternal] = useSendInternalDeliveredMutation();
  const send = dashboardType === 'orders' ? sendCurrent : sendInternal;
  const isLoading = dashboardType === 'orders' ? isLoadingCurrent : isLoadingInternal;
  const [updateCurrent] = useUpdateOrderMutation();
  const [updateInternal] = useUpdateInternalOrderMutation();
  const update = dashboardType === 'orders' ? updateCurrent : updateInternal;
  const formMethods = useForm({
    mode: 'onChange',
    resolver: yupResolver(dashboardType === 'orders' ? schema : internalSchema),
    defaultValues
  });

  const ttmFormMethods = useForm({
    mode: 'onChange',
    resolver: yupResolver(TTMschema),
    defaultValues: defaultTTMValues
  });

  const onSubmit = async (data) => {
    const toastId = toast.loading('Loading...');
    const formData = new FormData();

    if (dashboardType !== 'orders') {
      data?.items?.forEach(el => {
        if (el?.certificate) formData.append(`details.${el?.id}.certificate`, el?.certificate);
        if (el?.storage_number) formData.append(`details.${el?.id}.storage_number`, el?.storage_number);
        if (el?.serial_number) formData.append(`details.${el?.id}.serial_number`, el?.serial_number);
        if (el?.storage_zone) formData.append(`details.${el?.id}.storage_zone`, el?.storage_zone);
        if (el?.expected_day_delivery) formData.append('expected_day_delivery', el?.expected_day_delivery);
        if (!['Repair_in', 'Overhaul_in'].includes(order?.part_type) && el?.estimated_date) formData.append('estimated_date', el?.estimated_date);
      });
    } else {
      formData.append('storage_number', data?.items?.[0]?.storage_number);
      formData.append('serial_number', data?.items?.[0]?.serial_number);
      formData.append('storage_zone', data?.items?.[0]?.storage_zone);
      formData.append('expected_day_delivery', data?.items?.[0]?.expected_day_delivery);
    }

    const { error } = await send({ id: order.id, data: formData });

    if (error) {
      toast.error(error.data.message || 'Something went wrong', {
        id: toastId
      });

      return;
    }
    onClose();
    toast.success('Successfully!', {
      id: toastId
    });
  };

  const onSaveEstimatedDate = async (data) => {
    const toastId = toast.loading('Loading...');
    const formData = {
      estimated_date: data?.items?.[0]?.estimated_date,
      priority: order?.priority
    };
  
    // formData.append('estimated_date', data?.items?.[0]?.estimated_date);
    // formData.append('priority', order?.priority);

    const { error } = await update({ id: order.id, data: formData });

    if (error) {
      toast.error(error.data.message || 'Something went wrong', {
        id: toastId
      });

      return;
    }

    toast.success('Successfully!', {
      id: toastId
    });
  };

  const onSubmitTTM = async (data) => {
    const toastId = toast.loading('Loading...');
    const sendData = {
      ttn_code: data?.ttm_code,
      priority: order?.priority
    };
    const { error } = await update({ id: order.id, data: (dashboardType === 'orders') ? data : sendData });

    if (error) {
      toast.error(error.data.message || 'Something went wrong', {
        id: toastId
      });

      return;
    }

    refetch();

    toast.success('Successfully!', {
      id: toastId
    });
  };

  return {
    ttmFormMethods,
    formMethods,
    defaultValues,
    handleSubmit: formMethods.handleSubmit,
    onSubmitTTM,
    handleSubmitTTM: ttmFormMethods.handleSubmit,
    onSubmit,
    isLoading,
    onSaveEstimatedDate
  };
};
