import {
  Card, CardContent,
  Grid,
  Typography
} from '@mui/material';
import { styled } from '@mui/material/styles';
import React from 'react';
import { toast } from 'react-hot-toast';
import { useDownloadUserInvoiceMutation } from '../../../../../store/session';
import { INVOICE_STATUSES } from '../../../../Finance/InvoiceManagement/constants';
import { handleGetName } from '../constants';

const StyledCard = styled(Card)({
  margin: '10px 0',
  boxShadow: '0 3px 5px 2px rgba(0, 0, 0, 0.1)',
});

const TitleTypography = styled(Typography)({
  fontWeight: 'bold',
});

const DocsTableRow = ({ invoice }) => {
  const [downloadUserInvoice] = useDownloadUserInvoiceMutation();

  const handleOpen = async( id ) => {
    const toastId = toast.loading('Loading...');

    const res = await downloadUserInvoice(id);

    if (res.error) {
      toast.error('Something went wrong.', {
        id: toastId,
      });

      return;
    }

    if (res.data instanceof Blob) {
      // Create a URL for the Blob
      const fileURL = URL.createObjectURL(res.data);
      window.open(fileURL, '_blank');
    } else {
      // If res.data is already a URL
      window.open(res.data, '_blank');
    }

    toast.success('Success!', {
      id: toastId,
    });
  };

  return (
    <StyledCard>
      <CardContent>
        <Grid container alignItems="center" spacing={2}>
          <Grid item xs={3} sx={{ overflow: 'hidden', textWrap: 'nowrap' }}>
            <TitleTypography
              variant="body1"
              onClick={() => handleOpen(invoice.id)}
              sx={{
                color: 'rgb(108, 108, 241)',
                cursor: 'pointer',
                textTransform: 'capitalize',
                whiteSpace: 'break-spaces',
                wordBreak: 'break-all'
              }}
            >
              {handleGetName(invoice?.path)}
            </TitleTypography>
          </Grid>
          <Grid item xs={3}>
            {INVOICE_STATUSES.find(inv => (+inv?.value === +invoice?.status))?.icon || ''}
          </Grid>
          <Grid item xs={3}>
            <Typography>{invoice?.created_at}</Typography>
          </Grid>
          <Grid item xs={3} container justifyContent="flex-end">
            <Typography>{invoice?.sender}</Typography>
          </Grid>
        </Grid>
      </CardContent>
    </StyledCard>
  ); };

export default DocsTableRow;
