import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import React from 'react';
import Button from '../../../components/Button';
import CustomProgressBar from '../../../components/EditableTable/CustomProgressBar';
import { HeaderName } from '../../../components/EditableTable/styles';
import LinkButton from '../../../components/LinkButton';
import { handleStatusChip } from '../../../constans/logistics';
import { GreenButton, RedButton, TextWrap } from '../../Logistics/style';
import { priceStrToNumber } from '../utils';

const getTextDecorationForPrice = (row) => {
  if (row.completed) {
    return {};
  }
  if ((row.price_info.invoice === '0,00' && row.price_info.estimated !== '0,00') || row.price_info.invoice_unpaid !== '0,00') {
    return { fontStyle: 'italic', fontSize: '0.9rem' };
  }

  return {};
};
const getTextDecorationForDeliveryPrice = (row) => {
  if (row.completed) {
    return {};
  }
  const hasPendingPaymentOrUnpaid = [...row.delivery_price_info.main, ...row.delivery_price_info.part].some(
    (value) => value.includes('Pending Payment') || value.includes('Unpaid')
  );

  return hasPendingPaymentOrUnpaid ? { fontStyle: 'italic', fontSize: '0.9rem' } : {};
};
export const columns = ({
  handleInvoicesDrawer
}) => ([
  {
    flex: 0.1,
    field: 'id',
    minWidth: 100,
    headerName: 'Order Number',
    renderHeader: () => (
      // eslint-disable-next-line react/jsx-filename-extension
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
          fontWeight: '650'
        }}
      >
        <Typography style={HeaderName}>Order Number</Typography>
      </Box>
    ),
    renderCell: ({ row }) => (
      <Typography
        variant="h6"
        noWrap
        sx={{ textTransform: 'capitalize', color: 'blue' }}
      >
        <LinkButton path={`/logistics/my-orders/orders/order-info/${row.id}`}>
          #
          {row.id}
        </LinkButton>
      </Typography>
    ),
  },
  {
    flex: 0.15,
    field: 'price',
    minWidth: 140,
    headerName: 'Price',
    sortable: false,
    renderHeader: () => (
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
        }}
      >
        <Typography style={HeaderName}>Price</Typography>
      </Box>
    ),
    renderCell: ({ row }) => (
      <Tooltip
        placement="top-end"
        variant="soft"
        title={(
          <Box>
            <Typography sx={{ color: '#fff' }}>
              {`Paid Sum - ${row.price_info.invoice} EUR`}
            </Typography>
            {row.price_info.invoice_unpaid !== '0,00' ? (
              <Typography sx={{ color: '#fff' }}>
                {`Pending Payment Sum  - ${row.price_info.invoice_unpaid} EUR`}
              </Typography>
            ) : null}
            {row?.order_type === 'exchange' ? (
              <>
                <Typography sx={{ color: '#fff' }}>
                  {`Core Value - ${row.price_info.estimated} EUR`}
                </Typography>
                <Typography sx={{ color: '#fff' }}>
                  {`Exchange Fee - ${row.price_info.exchange} EUR`}
                </Typography>
              </>
            ) : (
              <Typography sx={{ color: '#fff' }}>
                {`Estimated Price - ${row.price_info.estimated} EUR`}
              </Typography>
            )}
          </Box>
        )}
      >
        <Box sx={{ width: '100%' }}>
          <Typography sx={{ ...TextWrap, ...getTextDecorationForPrice(row) }}>
            {`${row.price} EUR`}
          </Typography>
          <CustomProgressBar 
            bar1={((100 * priceStrToNumber(row?.price_info?.invoice)) / priceStrToNumber(row?.price_info?.estimated))}
            bar2={((100 * priceStrToNumber(row?.price_info?.invoice_unpaid)) / priceStrToNumber(row?.price_info?.estimated))}
            isCompleted={row?.completed}
          />
        </Box>
      </Tooltip>
    ),
  },
  {
    flex: 0.15,
    field: 'delivery_price',
    minWidth: 140,
    headerName: 'Delivery Fees',
    sortable: false,
    renderHeader: () => (
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
        }}
      >
        <Typography style={HeaderName}>Delivery Fees</Typography>
      </Box>
    ),
    renderCell: ({ row }) => (row.delivery_price_info.main.length || row.delivery_price_info.part.length ? (
      <Tooltip
        placement="top-end"
        variant="soft"
        title={(
          <Box>
            {row.delivery_price_info.main.map((value) => (
              <Typography key={value} sx={{ color: '#fff' }}>
                {value}
              </Typography>
            ))}
            {row.delivery_price_info.main.length && row.delivery_price_info.part.length ? (
              <Box sx={{ mt: 3, mb: 3, borderBottom: '1px solid #e9edef' }} />
            ) : null}
            {row.delivery_price_info.part.map((value) => (
              <Typography key={value} sx={{ color: '#fff' }}>
                {value}
              </Typography>
            ))}
          </Box>
        )}
      >
        <Box>
          <Typography sx={{ ...TextWrap, ...getTextDecorationForDeliveryPrice(row) }}>
            {`${row.delivery_price_sum} EUR`}
          </Typography>
        </Box>
      </Tooltip>
    ) : (
      <Box>
        <Typography sx={TextWrap}>
          {`${row.delivery_price_sum} EUR`}
        </Typography>
      </Box>
    )),
  },
  {
    flex: 0.15,
    field: 'delivery_date',
    minWidth: 140,
    headerName: 'Delivery Date',
    sortable: false,
    renderHeader: () => (
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
        }}
      >
        <Typography style={HeaderName}>Delivery Date</Typography>
      </Box>
    ),
    renderCell: ({ row }) => (row?.delivered_date ? (
      <Box>
        <Typography sx={TextWrap}>
          {row.delivered_date}
        </Typography>
      </Box>
    ) : (
      <Tooltip
        placement="top-end"
        variant="soft"
        title={(
          <Box>
            <Typography sx={{ color: '#fff' }}>
              This is estimated delivery date
            </Typography>
          </Box>
        )}
      >
        <Box>
          <Typography sx={{ ...TextWrap, fontStyle: 'italic', fontSize: '0.9rem' }}>
            {row?.estimated_date}
          </Typography>
        </Box>
      </Tooltip>
    )),
  },
  {
    flex: 0.1,
    field: 'status',
    minWidth: 120,
    headerName: 'Status',
    align: 'left',
    renderHeader: () => (
      // eslint-disable-next-line react/jsx-filename-extension
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
          fontWeight: '650'
        }}
      >
        <Typography style={HeaderName}>Status</Typography>
      </Box>
    ),
    renderCell: ({ row }) => handleStatusChip(row.status, row?.decline_reason, row?.ttn_code, row?.is_aircraft_body)
  },
  {
    flex: 0.1,
    field: 'order_type',
    minWidth: 120,
    headerName: 'Order Type',
    align: 'left',
    renderHeader: () => (
      // eslint-disable-next-line react/jsx-filename-extension
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
          fontWeight: '650'
        }}
      >
        <Typography style={HeaderName}>Order Type</Typography>
      </Box>
    ),
    renderCell: ({ row }) => (
      <Box>
        <Typography sx={TextWrap}>
          {row?.order_type ? row.order_type.replace('_', ' ') : ''}
        </Typography>
      </Box>
    ),
  },
  {
    flex: 0.15,
    field: 'invoices',
    minWidth: 120,
    headerName: 'Invoices',
    sortable: false,
    renderHeader: () => (
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
        }}
      >
        <Typography style={HeaderName}>Invoices</Typography>
      </Box>
    ),
    renderCell: ({ row }) => (
      <Box>
        <Button
          sx={row?.invoices.length ? GreenButton : RedButton}
          size="small"
          onClick={() => handleInvoicesDrawer(row?.id)}
        >
          Invoices
        </Button>
      </Box>
    ),
  }
]);
