import { Card, Grid } from '@mui/material';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import EditableTable from '../../../components/EditableTable';
import { useDeleteAircraftTypeMutation, useGetAircraftTypeListQuery } from '../../../store/session';
import AircraftTypeButton from '../components/AircraftTypeButton';
import { columns } from './columns';

const Table = (props) => {
  const navigate = useNavigate();
  const { title } = props;
  const btnTitle = 'Add Type';

  return (
    <Grid container spacing={6}>
      <Grid item xs={12}>
        <Card>
          <Box sx={{
            p: 5, pb: 1, display: 'flex', flexWrap: 'wrap', alignItems: 'center', justifyContent: 'space-between',
          }}
          >
            <Typography variant="h3" noWrap sx={{ textTransform: 'capitalize' }}>
              {title}
            </Typography>

            <Box>
              <AircraftTypeButton title={btnTitle} />
            </Box>
          </Box>
          <EditableTable
            getRowId={(row) => row.id}
            useHook={useGetAircraftTypeListQuery}
            useHookDelete={useDeleteAircraftTypeMutation}
            getRowHeight={() => 'auto'}
            sortByType="asc"
            sortByActive="id"
            navigate={navigate}
            columns={columns}
            style={{
              '& .MuiDataGrid-columnHeaders': { borderRadius: 0 },
              '& .MuiDataGrid-virtualScroller': {
                marginTop: '0px !important',
              },
            }}
          />
        </Card>
      </Grid>
    </Grid>
  );
};

export default Table;
