import { RefreshRounded } from '@mui/icons-material';
import BuildIcon from '@mui/icons-material/Build';
import ConstructionIcon from '@mui/icons-material/Construction';
import KeyboardReturnIcon from '@mui/icons-material/KeyboardReturn';
import LoopIcon from '@mui/icons-material/Loop';
import { TabContext, TabPanel } from '@mui/lab';
import {
  Box, Card, Tab, Tabs, Typography, useMediaQuery
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Button from '../../../components/Button';
import EditableTable from '../../../components/EditableTable';
import { BoxIcon, StorageIcon } from '../../../components/Icons';
import BoxesIcon from '../../../components/Icons/BoxesIcon';
import { useAppDispatch } from '../../../hooks/toolkitHooks';
import { useDeleteStorageOrderMutation, useGetAircraftListQuery, useGetStorageOrdersTableQuery } from '../../../store/session';
import { fetchCurrencies, useGetTotalOrdersStorageQuery } from '../../../store/slices/logisticsSlice';
import { usePermissions } from '../../../utils/hooks/usePermissions';
import { btnS, headerBtn } from '../../Invoices/style';
import {
  GreenButton,
  TextWithIcon,
  YellowButton
} from '../style';
import { columns } from './columns';
import PriceDrawer from './PriceDrawer/PriceDrawer';
import ReportDefectivePart from './ReportDefectivePart';

const MyOrders = () => {
  const { data: AircraftList } = useGetAircraftListQuery();
  const { data: totalOrders, refetch: refetchTotalOrders } = useGetTotalOrdersStorageQuery();
  const navigate = useNavigate();

  const aircraftOptions = AircraftList?.map((aircraft) => ({
    label: aircraft.aircraft_registration,
    value: aircraft.id
  }));
  const [value, setValue] = useState('1');
  const [priceDrawerState, setPriceDrawerState] = useState({
    isOpen: false,
    orderId: null
  });
  const [openReportDrawer, setOpenReportDrawer] = useState(false);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  // Permission
  const hasPermissions = usePermissions('logistics_storage_write');
  const externalStorageMaster = usePermissions('logistics_storage_master');
  const hasInternalPermissions = usePermissions('internal_logistics_storage_master');
  const reportPartPermission = usePermissions('logistics_storage_write');

  useEffect(() => {
    if (aircraftOptions) {
      aircraftOptions?.push({ value: 0, label: 'N/A' });
      aircraftOptions?.push({ value: '', label: '-' });
    }
  }, [aircraftOptions]);

  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(fetchCurrencies());
  }, []);
  const { currencies } = useSelector((state) => state.logistics);

  const [arFilter, setArFilter] = useState(null);
  const [partNumber, setPartNumber] = useState('');
  const [partName, setPartName] = useState('');
  const [refresh, setRefresh] = useState(0);

  const maxWidth800px = useMediaQuery('(max-width:800px)');

  const handleRefresh = () => {
    setRefresh(!refresh);
  };

  const handleOpenReportDrawer = () => {
    setOpenReportDrawer(true);
  };
  const handleCloseReportDrawer = () => {
    setOpenReportDrawer(false);
  };

  const handleOpenPriceDrawer = (isOpen = false, orderId = null) => {
    setPriceDrawerState(prev => ({
      ...prev,
      isOpen,
      orderId
    }));
  };

  const renderTable = (part_type) => (
    <EditableTable
      useHook={useGetStorageOrdersTableQuery}
      useHookDelete={useDeleteStorageOrderMutation}
      columns={columns}
      sortByType="DESC"
      sortByActive="id"
      // getRowClassName={(params) => 'row-m row-cnld'}
      getRowHeight={() => 'auto'}
      getRowId={(row) => row.id}
      tableParams={{
        part_type,
        aircraft_id: arFilter,
        part_number: partNumber,
        part_name: partName,
        refresh
      }}
      currencies={currencies}
      aircraftOptions={aircraftOptions}
      partType={part_type}
      permissions={hasPermissions}
      handleOpenPriceDrawer={handleOpenPriceDrawer}
      internalPermissions={hasInternalPermissions}
      externalStorageMaster={externalStorageMaster}
      deleteCallback={refetchTotalOrders}
    />
  );

  return (
    <>
      <Card
        sx={{
          mt: 3,
          padding: '1rem'
        }}
      >
        <Box
          sx={{
            ...headerBtn, display: 'grid', gridTemplateColumns: '1fr 1fr 1fr', ...(maxWidth800px && { display: 'flex', flexDirection: 'column', gap: 5 })
          }}
        >
          <Box sx={maxWidth800px ? { alignSelf: 'start' } : {}}>
            <Typography noWrap variant="h3" sx={TextWithIcon}>
              <StorageIcon />
              Storage
            </Typography>
          </Box>
          <Box sx={{ justifySelf: 'center' }}>
            <Tabs
              value={value}
              onChange={handleChange}
              sx={maxWidth800px ? {
                '& .MuiTabs-flexContainer': {
                  overflowX: 'auto'
                }
              } : {}}
            >
              <Tab value="1" label="Parts" icon={<BoxIcon />} />
              <Tab value="2" label="Instruments" icon={<BuildIcon />} />
              <Tab value="3" label="Exchange Send" icon={<LoopIcon />} />
              <Tab value="4" label="Return" icon={<KeyboardReturnIcon />} />
              <Tab
                value="5"
                label="Repair"
                icon={(
                  <Box sx={{ position: 'relative' }}>
                    <ConstructionIcon />
                    <Box sx={{
                      position: 'absolute', top: '-12px', right: '-24px' 
                    }}
                    >
                      {totalOrders?.repair || 0}
                    </Box>
                  </Box>
)}
              />
            </Tabs>
          </Box>
          <Box sx={maxWidth800px ? {
            display: 'flex',
            gap: '5px',
            flexDirection: 'column',
            width: '100%'
          } : { display: 'flex', gap: '10px', justifySelf: 'end' }}
          >
            {/* {(value === '5') && (
            <Button
              sx={GreenButton}
              title="Price"
              onClick={() => handleOpenPriceDrawer(true)}
              disabled={(!hasPermissions && !hasInternalPermissions && !externalStorageMaster)}
            />
            )} */}
            <Button
              sx={GreenButton}
              startIcon={<BoxIcon />}
              title="Create"
              onClick={() => navigate(`/logistics/orders-storage/create_${+value === 1 ? 'part' : 'instrument'}`)}
              disabled={(!hasPermissions && !hasInternalPermissions && !externalStorageMaster)}
            />
            <Button
              startIcon={<BoxesIcon />}
              title="Dashboard"
              onClick={() => navigate('/logistics/dashboard/orders')}
            />
            <Button
              disabled={!reportPartPermission}
              title="Report
              Part"
              onClick={handleOpenReportDrawer}
            />
          </Box>
        </Box>
      </Card>

      <Card
        sx={{
          mt: 3,
          padding: '1rem'
        }}
      >
        <Box sx={{
          display: 'flex',
          justifyContent: 'flex-end'
        }}
        >
          <Button
            startIcon={<RefreshRounded />}
            sx={{ ...(!maxWidth800px && { ml: 2 }), ...(maxWidth800px && btnS), ...YellowButton }}
            title="Refresh"
            onClick={handleRefresh}
          />
        </Box>
        <Box>
          <TabContext value={value}>
            <TabPanel value="1">
              {renderTable('all_parts')}
            </TabPanel>
            <TabPanel value="2">
              {renderTable('instrument')}
            </TabPanel>
            <TabPanel value="3">
              {renderTable('exchange')}
            </TabPanel>
            <TabPanel value="4">
              {renderTable('return')}
            </TabPanel>
            <TabPanel value="5">
              {renderTable('repair')}
            </TabPanel>
          </TabContext>
        </Box>
      </Card>
      <ReportDefectivePart open={openReportDrawer} onClose={handleCloseReportDrawer} refetch={handleRefresh} />
      <PriceDrawer open={priceDrawerState.isOpen} onClose={() => handleOpenPriceDrawer(false)} currencies={currencies} orderId={priceDrawerState.orderId} handleRefresh={handleRefresh} aircraftOptions={aircraftOptions} />
    </>
  );
};

export default MyOrders;
