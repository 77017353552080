import { Box, Typography } from '@mui/material';
import React from 'react';
import { CustomProgressBarStyled, ProgressBarBar, ProgressBarContainer } from './style';

const CustomProgressBar = (props) => {
  const { 
    containerStyle = {},
    bar1 = 0,
    bar2 = 0,
    isCompleted = false
  } = props;

  return (
    <CustomProgressBarStyled sx={containerStyle}>
      <ProgressBarContainer>
        <ProgressBarBar sx={{
          width: `${bar1}%`,
          borderBottomLeftRadius: '10px', 
          borderTopLeftRadius: '10px' 
        }}
        />
        <ProgressBarBar sx={{ width: `${bar2}%`, backgroundColor: '#f8e63c' }} />
      </ProgressBarContainer>
      <Box sx={{ minWidth: 35 }}>
        <Typography
          variant="body2"
          sx={{ color: 'text.secondary' }}
        >
          {`${!isCompleted ? Math.round(bar1 + bar2 || 0) : 100}%`}
        </Typography>
      </Box>
    </CustomProgressBarStyled>
  ); 
};

export default CustomProgressBar;