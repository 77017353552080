
import {
  array, boolean,
  mixed, number,
  object, string
} from 'yup';

export const defaultValues = {
  invoice_sum: '',
  file: '',
  description: 'Order ID: ',
  invoice_number: '',
  invoice_name: '',
  due_payment_date: null,
  currency_id: '',
  is_proforma: 0,
  parent_id: null,
  orders: [],
  prices: [],
  vendor_payout_amount: null,
  vendor_payout_description: '',
  payment_type: ''
};

export const schema = object().shape({
  payment_type: string()
    .required('${label} cannot be blank')
    .label('Invoice Type'),
  file: mixed().test('isEmpty', 'File cannot be blank', (value) => !!value ).nullable(),
  is_proforma: boolean(),
  invoice_sum: string()
    .nullable()
    .required('${label} cannot be blank')
    .label('SUM'),
  description: string()
    .nullable()
    .required('${label} cannot be blank')
    .label('Description'),
  invoice_number: string()
    .nullable()
    .required('${label} cannot be blank')
    .label('Invoice Number'),
  invoice_name: string()
    .nullable()
    .required('${label} cannot be blank')
    .label('Supplier'),
  currency: object()
    .nullable()
    .required('${label} cannot be blank')
    .label('Currency'),
  currency_id: number()
    .nullable()
    .required('${label} cannot be blank')
    .label('Currency'),
  due_payment_date: string()
    .nullable()
    .required('${label} cannot be blank')
    .label('Deadline Payment'),
  orders: array()
    .nullable()
    .required('${label} cannot be blank')
    .label('Other Orders'),
  prices: array()
    .nullable()
    .label('Prices'),
});
