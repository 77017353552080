import {
  array,
  boolean,
  number, object, string
} from 'yup';

export const defaultValues = {
  prices: [
    {
      amount: 1,
      currency_id: '11',
      suggested: true,
      address_from: '',
      from: '',
      address_to: '',
      address_to_supplier_id: 1,
      estimated_date: '',
      estimated_delivery_price: 1,
      estimated_delivery_currency_id: 11
    }
  ]
};

export const schema = object().shape({
  prices: array(object().shape({
    amount: number()
      .required('${label} cannot be blank.')
      .label('Amount'),
    currency_id: string()
      .required('${label} cannot be blank.')
      .label('Currency'),
    suggested: boolean()
      .required('${label} cannot be blank.')
      .label('Suggested'),
    address_from: string()
      .required('${label} cannot be blank.')
      .label('Address From'),
    from: string()
      .nullable()
      .label('From'),
    address_to: string()
      .required('${label} cannot be blank.')
      .label('Address To'),
    address_to_supplier_id: number()
      .required('${label} cannot be blank.')
      .label('Supplier ID'),
    estimated_date: string(),
    estimated_delivery_price: string()
      .nullable()
      .label('Estimated Delivery Price'),
    estimated_delivery_currency_id: string()
      .nullable()
      .label('Estimated Delivery Currency'),
  })),
});
