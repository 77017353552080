import {
  Box, Card, Divider, Typography
} from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import React from 'react';
import ContentPageLoader from '../../components/Loader/ContentPageLoader';
import { useGetMainDeckListQuery } from '../../store/session';
import ButtonsGroup from './ButtonsGroup';
import MainCard from './MainCard/MainCard';
import {
  ContainerCard,
  ContainerCardXs,
} from './styles';

const MainDeck = () => {
  const maxWidth990px = useMediaQuery('(max-width:990px)');

  const { data, isLoading } = useGetMainDeckListQuery();

  if (isLoading) {
    return <ContentPageLoader />;
  }

  return (
    <Card sx={{ padding: '1rem' }}>
      <Typography variant="h3" noWrap sx={{ textTransform: 'capitalize' }}>
        Main Deck
      </Typography>
      <ButtonsGroup />
      <Divider />
      <Box sx={{ ...ContainerCard, ...(maxWidth990px && ContainerCardXs) }}>
        {data?.map((item) => (
          <MainCard item={item} />
        ))}
      </Box>
    </Card>
  );
};

export default MainDeck;
