import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import Typography from '@mui/material/Typography';
import { ListStatus } from 'mdi-material-ui';
import React from 'react';
import { Link } from 'react-router-dom';
import Button from '../../../components/Button';
import ButtonIcon from '../../../components/ButtonIcon';
import { HeaderName, StyledDelete, StyledPencil } from '../../../components/EditableTable/styles';
import LinkButton from '../../../components/LinkButton/Button';
import { PRIORITY, handleStatusChip } from '../../../constans/logistics';
import Checkbox from '../../../form/components/Checkbox';
import { TextWrap } from '../style';

export const columns = ({
  handleOpenDeleteModal,
  aircraftReservationCreator,
  handleOpenAllInfo,
  aircraftDropDownState,
  setCurrentRowTable,
  handleOrderCompleted,
  disabledOrderCheckbox,
  isLoadingToggleOrderCompleted
}) => ([
  {
    flex: 0.1,
    field: 'all_info',
    minWidth: 120,
    headerName: 'Information',
    renderHeader: () => (
      // eslint-disable-next-line react/jsx-filename-extension
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
          fontWeight: '650'
        }}
      >
        <Typography style={{ ...(HeaderName), fontWeight: '650' }}></Typography>
      </Box>
    ),
    renderCell: ({ row }) => (
      <Button
        title="Info"
        onClick={() => handleOpenAllInfo(row?.id)}
      />
    ),
  },
  {
    flex: 0.1,
    field: 'completed',
    minWidth: 130,
    headerName: 'Completed',
    renderHeader: () => (
      <Box>
        <Typography style={HeaderName}>Completed</Typography>
      </Box>
    ),
    renderCell: ({ row }) => (
      <Box>
        {(row?.status !== 5) && (
        <Checkbox
          disabled={((disabledOrderCheckbox === row?.id) && isLoadingToggleOrderCompleted)}
          value={row?.completed}
          onClick={() => handleOrderCompleted(row?.id, row?.completed)}
        />
        )}
      </Box>
    ),
  }, // completed
  {
    flex: 0.1,
    field: 'id',
    minWidth: 100,
    headerName: '#',
    renderHeader: () => (
      // eslint-disable-next-line react/jsx-filename-extension
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
          fontWeight: '650'
        }}
      >
        <Typography style={{ ...(HeaderName), fontWeight: '650' }}>#</Typography>
      </Box>
    ),
    renderCell: ({ row }) => (
      <Typography
        variant="h6"
        noWrap
        sx={{ textTransform: 'capitalize' }}
      >
        #
        {row.id}
      </Typography>
    ),
  },
  {
    flex: 0.1,
    field: 'priority',
    minWidth: 100,
    headerName: 'Priority',
    renderHeader: () => (
      // eslint-disable-next-line react/jsx-filename-extension
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
          fontWeight: '650'
        }}
      >
        <Typography style={{ ...(HeaderName), fontWeight: '650' }}>
          <PriorityHighIcon />
        </Typography>
      </Box>
    ),
    renderCell: ({ row }) => PRIORITY.find(i => i.value === row.priority).icon
  },
  {
    flex: 0.1,
    field: 'status',
    minWidth: 120,
    headerName: 'Status',
    align: 'left',
    renderHeader: () => (
      // eslint-disable-next-line react/jsx-filename-extension
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
          fontWeight: '650'
        }}
      >
        <Typography style={{ ...(HeaderName), fontWeight: '650' }}>
          <ListStatus />
        </Typography>
      </Box>
    ),
    renderCell: ({ row }) => handleStatusChip(row.status, row?.decline_reason, row?.ttm_code, row?.is_aircraft_body)
  },
  {
    flex: 0.2,
    field: 'aircraft_id',
    minWidth: 160,
    headerName: 'Aircraft Reservation',
    renderHeader: () => (
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
          fontWeight: '650'
        }}
      >
        <Typography style={{ ...(HeaderName), fontWeight: '650' }}>Aircraft Reservation</Typography>
      </Box>
    ),
    renderCell: ({ row }) => (
      <Typography
        variant="subtitle1"
        noWrap
        sx={{ textTransform: 'capitalize', cursor: 'pointer' }}
        onClick={(event) => {
          event.stopPropagation();
          aircraftDropDownState?.openMenu(event);
          setCurrentRowTable(row);
        }}
      >
        <Chip
          label={aircraftReservationCreator ? (
            <Box sx={{ display: 'flex' }}>
              <Typography>{row?.aircraft_name ?? 'N/A'}</Typography>
              <KeyboardArrowDownIcon />
            </Box>
          ) : (row?.aircraft_name ?? 'N/A')}
          color="secondary"
          variant="outlined"
        />
      </Typography>
    ),
  },
  {
    flex: 0.15,
    field: 'work_order_task',
    minWidth: 140,
    renderHeader: () => (
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
          fontWeight: '650'
        }}
      >
        <Typography style={{ ...(HeaderName), fontWeight: '650' }}>Work Order Task</Typography>
      </Box>
    ),
    renderCell: ({ row }) => {
      if (row.work_order_task) {
        return (
          <Typography>
            <Link style={{ 'textDecoration': 'none' }} to={`/maintenance/work-order-task/update/${row.work_order_task}`} target="_blank">{row.name}</Link>
          </Typography>
        );
      }

      return (
        <Typography
          variant="h6"
          noWrap
          sx={TextWrap}
        >
          N/A
        </Typography>
      );
    },
  },
  {
    flex: 0.2,
    field: 'storage',
    minWidth: 180,
    headerName: 'Storage',
    renderHeader: () => (
      // eslint-disable-next-line react/jsx-filename-extension
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
          fontWeight: '650'
        }}
      >
        <Typography style={{ ...(HeaderName), fontWeight: '650' }}>Storage</Typography>
      </Box>
    ),
    renderCell: ({ row }) => (
      <Typography
        variant="h6"
        noWrap
        sx={TextWrap}
      >
        {row.storage}
      </Typography>
    ),
  },
  {
    flex: 0.2,
    field: 'part_number',
    minWidth: 180,
    headerName: 'Part Number',
    renderHeader: () => (
      // eslint-disable-next-line react/jsx-filename-extension
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
          fontWeight: '650'
        }}
      >
        <Typography style={{ ...(HeaderName), fontWeight: '650' }}>Part Number</Typography>
      </Box>
    ),
    renderCell: ({ row }) => (
      <Typography
        variant="h6"
        noWrap
        sx={TextWrap}
      >
        {row.part_number}
      </Typography>
    ),
  },
  {
    flex: 0.2,
    field: 'part_name',
    minWidth: 180,
    headerName: 'Part Name',
    renderHeader: () => (
      // eslint-disable-next-line react/jsx-filename-extension
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
          fontWeight: '650'
        }}
      >
        <Typography style={{ ...(HeaderName), fontWeight: '650' }}>Part Name</Typography>
      </Box>
    ),
    renderCell: ({ row }) => (
      <Typography
        variant="h6"
        noWrap
        sx={TextWrap}
      >
        {row.part_name ?? 'N/A'}
      </Typography>
    ),
  },
  {
    flex: 0.2,
    field: 'part_type',
    minWidth: 180,
    headerName: 'Part Type',
    renderHeader: () => (
      // eslint-disable-next-line react/jsx-filename-extension
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
          fontWeight: '650'
        }}
      >
        <Typography style={{ ...(HeaderName), fontWeight: '650' }}>Part Type</Typography>
      </Box>
    ),
    renderCell: ({ row }) => (
      <Typography
        variant="h6"
        noWrap
        sx={TextWrap}
      >
        {row.part_type ?? 'N/A'}
      </Typography>
    ),
  },
  {
    flex: 0.2,
    field: 'part_count',
    minWidth: 180,
    headerName: 'Quantity',
    renderHeader: () => (
      // eslint-disable-next-line react/jsx-filename-extension
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
        }}
      >
        <Typography style={{ ...(HeaderName), fontWeight: '650' }}>Quantity</Typography>
      </Box>
    ),
    renderCell: ({ row }) => (
      <Typography
        variant="h6"
        noWrap
        sx={{ fontWeight: 'bold' }}
      >
        {`${row.part_count} ${row.unit ?? ''}`}
      </Typography>
    ),
  },
  {
    flex: 0.2,
    field: 'creator',
    minWidth: 220,
    headerName: 'Created by',
    renderHeader: () => (
      // eslint-disable-next-line react/jsx-filename-extension
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
        }}
      >
        <Typography style={{ ...(HeaderName), fontWeight: '650' }}>Created by</Typography>
      </Box>
    ),
    renderCell: ({ row }) => (
      <Typography
        variant="h6"
        noWrap
        sx={TextWrap}
      >
        {row?.creator?.name || row?.creator}
      </Typography>
    ),
  },
  {
    flex: 0.2,
    field: 'created',
    minWidth: 240,
    headerName: 'Created at / Update at',
    renderHeader: () => (
      // eslint-disable-next-line react/jsx-filename-extension
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
        }}
      >
        <Typography style={{ ...(HeaderName), fontWeight: '650' }}>Created at / Update at</Typography>
      </Box>
    ),
    renderCell: ({ row }) => (
      <Typography
        variant="h6"
        noWrap
        sx={TextWrap}
      >
        <span>
          Created:
          {' '}
          {row?.created}
        </span>
        <br />
        {row?.updated && (
        <span>
          Updated:
          {' '}
          {row?.updated}
        </span>
        )}
      </Typography>
    ),
  },
  {
    width: 100,
    field: 'actions',
    headerName: 'Actions',
    sortable: false,
    // eslint-disable-next-line consistent-return
    renderCell: ({ row }) => (
      <>
        <LinkButton disabled={row?.status !== 0} path={row?.status === 0 ? `/logistics/my-orders/orders/update/${row.id}` : null}>
          <StyledPencil />
        </LinkButton>
        <ButtonIcon onClick={() => handleOpenDeleteModal(row.id)}>
          <StyledDelete />
        </ButtonIcon>
      </>

    )
  },
]);
