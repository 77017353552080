import { yupResolver } from '@hookform/resolvers/yup';
import {
  Box,
  Button,
  Card,
  Divider,
  Grid,
  Typography
} from '@mui/material';
import React, { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import PdfViewer from '../../../../../../components/PdFViewer';
import Upload from '../../../../../../components/Upload';
import DatePicker from '../../../../../../form/components/DatePicker';
import FieldError from '../../../../../../form/components/FieldError';
import FormGroup from '../../../../../../form/components/FormGroup';
import Select from '../../../../../../form/components/Select';
import {
  useApprovePaymentMutation, useInvoiceListSearchQuery, useSetInvoiceGroupMutation
} from '../../../../../../store/slices/invoiceManagementSlice';
import { setDateValue, validateDatePickerValue } from '../../../../../../utils/setDateValue';
import { file, fileName, label } from '../../../../../Logistics/OrdersDashboard/components/forms/approved/invoices/style';
import { Filters, GreenButton } from '../../../../../Logistics/style';
import DeclineButton from '../DeclineButton';
import { defaultValues, schema } from './pendingPaymentSchema';

const PendingPaymentForm = ({
  invoiceId, onSubmit, s3Link, refetchInvoice, invoice 
}) => {
  const [approveInvoice, { isLoading }] = useApprovePaymentMutation();
  const [setInvoiceGroup, { isLoading: isLoadingSetGroup }] = useSetInvoiceGroupMutation();
  const { data: invoiceGroupList } = useInvoiceListSearchQuery({ invoice_id: invoiceId });
  const [nameFile, setNameFile] = useState();
  const [invoiceGroupState, setInvoiceGroupState] = useState([+invoiceId]);

  const invoiceGroupListOptions = (invoiceGroupList || [])?.map(inv => ({
    value: inv?.id,
    label: `${inv?.id} - ${inv?.invoice_name}`
  }));

  const {
    handleSubmit,
    setValue,
    control,
    formState: { errors }
  } = useForm({
    mode: 'onChange',
    defaultValues,
    resolver: yupResolver(schema),
    shouldFocusError: false,
  });

  const uploadFile = (e) => {
    setNameFile(e.target.files[0].name);
    setValue('proof_of_payment_file', e.target.files[0]);
  };

  const onSubmitForm = async (data) => {
    const toastId = toast.loading('Loading...');
    const formData = new FormData();

    formData.append('proof_of_payment_file', data.proof_of_payment_file);
    formData.append('payment_date', data.payment_date);

    const { error } = await approveInvoice({ id: invoiceId, data: formData });

    if (error) {
      toast.error(error.data.message || 'Something went wrong', {
        id: toastId
      });

      return;
    }

    toast.success('Successfully!', {
      id: toastId
    });
    onSubmit();
  };

  const onSetInvoiceGroup = async () => {
    const toastId = toast.loading('Loading...');

    const { error } = await setInvoiceGroup({ data: { invoice_ids: invoiceGroupState } });

    if (error) {
      toast.error(error.data.message || 'Something went wrong', {
        id: toastId
      });

      return;
    }
    
    const invIds = invoiceGroupState?.map(inv => `№${inv}`)?.join(', ');
    toast.success(`Invoices ${invIds || ''} of vendor ‘${invoice?.invoice_name || ''}’ have been successfully added to the group.`, {
      id: toastId
    });

    refetchInvoice(invoiceId);
    setInvoiceGroupState([]);
  };

  const handleSetInvoiceGroup = (value) => {
    if (!value?.length) {
      setInvoiceGroupState([+invoiceId]);
    } else if (!value.includes(+invoiceId)) {
      setInvoiceGroupState([+invoiceId, ...value]);
    } else {
      setInvoiceGroupState(value);
    }
  };

  return (
    <Grid container>
      {(!invoice?.group_invoices?.length && !!invoiceGroupListOptions.length) && (
      <Grid item xs={12}>
        <Card sx={{ padding: '1rem' }}>
          <Typography variant="h5">
            Invoices Group
          </Typography>
          <Select
            options={invoiceGroupListOptions}
            placeholder="Add Invoice Group"
            selectProps={{
              multiple: true,
              value: invoiceGroupState,
              onChange: (e) => {
                handleSetInvoiceGroup(e.target.value);
              }
            }}
          />
          <Button 
            sx={{ padding: '.35rem .7rem', mt: 4 }} 
            variant="contained"
            disabled={isLoadingSetGroup || !invoiceGroupState.length}
            onClick={onSetInvoiceGroup}
          >
            Set Group
          </Button>
        </Card>
        <Divider />
      </Grid>
      )}
      <Grid item xs={12}>
        <form onSubmit={handleSubmit(onSubmitForm)}>
          <Card sx={{ padding: '1rem' }}>
            <Grid container>
              <Grid item xs={12} sx={file}>
                <FormGroup sx={label} required label="Proof of payment" hasError={!!errors.proof_of_payment_file}>
                  <Controller
                    name="proof_of_payment_file"
                    control={control}
                    render={({ field }) => (
                      <Upload
                        {...field}
                        accept="application/pdf"
                        handleUpload={(e) => {
                          field.onChange(e);
                          uploadFile(e);
                        }}
                        title="Upload file"
                      />
                    )}
                  />
                  <FieldError error={errors.proof_of_payment_file} />
                </FormGroup>
                <Typography sx={fileName}>{nameFile}</Typography>
              </Grid>
              <Grid item xs={12}>
                <FormGroup sx={label} label="Payment date" required hasError={!!errors.payment_date}>
                  <Controller
                    name="payment_date"
                    control={control}
                    render={({ field }) => (
                      <DatePicker
                        {...field}
                        placeholderText="YYYY-MM-DD"
                        dateFormat="yyyy-MM-dd"
                        showMonthDropdown
                        showYearDropdown
                        onChange={(e) => {
                          field.onChange(e);
                          setDateValue(e, setValue, 'payment_date', 'YYYY-MM-DD');
                        }}
                        value={validateDatePickerValue(field.value)}
                      />
                    )}
                  />
                  <FieldError error={errors.payment_date} />
                </FormGroup>
              </Grid>
              <Grid item xs={12}>
                <Divider />
                <Box sx={{ ...(Filters), padding: '0px', justifyContent: 'space-between' }}>
                  <Button sx={GreenButton} variant="contained" type="submit" disabled={isLoading}>Payed</Button>
                  <DeclineButton invoiceId={invoiceId} onSubmit={onSubmit} />
                </Box>
              </Grid>
            </Grid>
          </Card>
        </form>
      </Grid>
      <Grid item xs={12} sx={{ minHeight: '750px' }}>
        <PdfViewer s3Link={s3Link} />
      </Grid>
    </Grid>
  );
};

export default PendingPaymentForm;
