import {
  Box, Grid, Typography
} from '@mui/material';
import ModalBase from '@mui/material/Modal';
import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { Link } from 'react-router-dom';
import {
  cancel, headerSx, modalSx, titleSx
} from '../../../../components/ApproveModal/styles';
import Button from '../../../../components/Button';
import FormGroup from '../../../../form/components/FormGroup';
import ServerAutocomplete from '../../../../form/components/ServerAutocomplete';
import { useSearchTransactionIMQuery } from '../../../../store/slices/invoiceManagementSlice';
import { useSetFileTransactionMutation } from '../../../../store/slices/salariesSlice';

const TransactionModal = (props) => {
  const {
    open, onClose, config, refetchCallback, salaryId, salaryDate
  } = props;
  const [currentTransaction, setCurrentTransaction] = useState(null);
  const [setFileTransaction, { isLoading: isSetFileTransactionLoading }] = useSetFileTransactionMutation();

  const handleSelectTransaction = (selection) => {
    const option = selection || { value: null, label: '' };

    setCurrentTransaction(option);
  };

  const onSubmit = async (id) => {
    const toastId = toast.loading('Loading...');

    const { error } = await setFileTransaction({ data: { transaction_id: currentTransaction?.value, file_id: id }, id: salaryId });

    if (error) {
      toast.error(error.data.message || 'Something went wrong', {
        id: toastId,
      });

      return;
    }

    refetchCallback();
    onClose();

    toast.success('Successfully!', {
      id: toastId,
    });
  };

  useEffect(() => {
    if (open) {
      setCurrentTransaction(config?.transaction_id ? { value: config?.transaction_id, label: config?.transaction_id } : null);
    }
  }, [open]);

  return (
    <ModalBase
      open={open}
      onClose={onClose}
    >
      <Box sx={modalSx}>
        <Box sx={headerSx}>
          <Typography sx={titleSx}>
            Add Transaction Link
          </Typography>
        </Box>
        <Box sx={{ mt: 5 }}>
          <Grid item xs={12}>
            <FormGroup label="Transaction (Internal ID)">
              <ServerAutocomplete
                withData
                value={currentTransaction}
                placeholder="Search Transactions"
                searchQueryFn={useSearchTransactionIMQuery}
                queryParams={{ created_date: salaryDate }}
                handleSelect={handleSelectTransaction}
                getOptionLabel={(option) => (typeof option === 'string' ? option : `${option?.label}`) || ''}
              />
            </FormGroup>
          </Grid>
          {config?.transaction_id && (
          <Grid item xs={12}>
            <Link to={`/finance/transactions?search_transaction=${config?.transaction_id}`} target="_blank">
              <Box>
                View Transaction
              </Box>
            </Link>
          </Grid>
          )}
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 5 }}>
          <Button onClick={() => onSubmit(config?.file_id)} title="Add" disabled={isSetFileTransactionLoading} />
          <Button onClick={onClose} sx={cancel} title="Cancel" />
        </Box>
      </Box>
    </ModalBase>
  );
};

export default TransactionModal;
