import { createSlice } from '@reduxjs/toolkit';
import { useProvideTags } from '../../utils/hooks/useProvideTags';
import { sessionApi } from '../session';

// State and actions
export const salariesSlice = createSlice({
  name: 'salaries',
  initialState: {},
});

// Logistics Endpoints
sessionApi.injectEndpoints({
  endpoints: (builder) => ({
    getSalariesTable: builder.query({
      query: (data) => `/payment_data/salaries${data}`,
      providesTags: (result, error) => useProvideTags(result, error, ['SALARIES_TABLE']),
    }),
    storeSalary: builder.mutation({
      query: (data) => ({
        url: '/payment_data/salaries',
        method: 'POST',
        body: data,
        headers: {
          Accept: 'application/json',
        },
      }),
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    updateSalary: builder.mutation({
      query: ({ id, data }) => ({
        url: `/payment_data/salaries/${id}`,
        method: 'PATCH',
        body: data,
        headers: {
          Accept: 'application/json',
        },
      }),
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    deleteSalary: builder.mutation({
      query: (id) => ({
        url: `/payment_data/salaries/${id}`,
        method: 'DELETE',
        headers: {
          Accept: 'application/json',
        },
      }),
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    getSalaryFiles: builder.mutation({
      query: (id) => ({
        url: `/payment_data/salaries/files/list/${id}`,
        method: 'GET',
        headers: {
          Accept: 'application/json',
        },
      }),
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    uploadSalaryFile: builder.mutation({
      query: ({ id, data }) => ({
        url: `/payment_data/salaries/files/upload/${id}`,
        method: 'POST',
        body: data,
        headers: {
          Accept: 'application/json',
        },

      }),
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    openSalaryFile: builder.mutation({
      query: ({ id }) => ({
        url: `/payment_data/salaries/files/open/${id}`,
        method: 'GET',
        responseHandler: async (response) => response.blob(),
        cache: 'no-cache',
      }),
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    deleteSalaryFile: builder.mutation({
      query: ({ id }) => ({
        url: `/payment_data/salaries/files/delete/${id}`,
        method: 'DELETE',
        headers: {
          Accept: 'application/json',
        },
      }),
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    searchTransaction: builder.query({
      query: ({ search, created_date }) => `/payment_data/salaries/transaction/list?search=${search}&date=${created_date}`,
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    searchPaymentData: builder.query({
      query: ({ search, department_id }) => `/payment_data/salaries/payments/list?search=${search}&department_id=${department_id}`,
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    generateSalaries: builder.mutation({
      query: (data) => ({
        url: '/payment_data/salaries/generate',
        method: 'POST',
        body: data,
        headers: {
          Accept: 'application/json',
        },
      }),
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    setFileTransaction: builder.mutation({
      query: ({ data, id }) => ({
        url: `/payment_data/salaries/transaction/set/${id}`,
        method: 'POST',
        body: data,
        headers: {
          Accept: 'application/json',
        },
      }),
      providesTags: (result, error) => useProvideTags(result, error),
    }),
  }),
});

export const {
  useGetSalariesTableQuery,
  useStoreSalaryMutation,
  useUpdateSalaryMutation,
  useDeleteSalaryMutation,
  // Salary files
  useGetSalaryFilesMutation,
  useUploadSalaryFileMutation,
  useOpenSalaryFileMutation,
  useDeleteSalaryFileMutation,
  useSearchTransactionQuery,
  useSearchPaymentDataQuery,
  useGenerateSalariesMutation,
  useSetFileTransactionMutation
} = sessionApi;

export default salariesSlice.reducer;
