import { createSlice } from '@reduxjs/toolkit';
import { encodeObjectToURIParams } from '../../utils/helper';
import { useProvideTags } from '../../utils/hooks/useProvideTags';
import { sessionApi } from '../session';

export const invoiceManagementSlice = createSlice({
  name: 'invoice_management',
  initialState: {},
});

sessionApi.injectEndpoints({
  endpoints: (builder) => ({
    getBoardData: builder.query({
      query: (params) => `/finance/invoice_management/board${params}`,
      providesTags: (result, error) => useProvideTags(result, error, ['GET_BOARD']),
    }),
    // getInvoiceDetails: builder.query({
    //   query: ({ id }) => `/finance/invoice_management/get-invoice/${id}`,
    //   providesTags: (result, error) => useProvideTags(result, error, ['GET_INVOICE']),
    // }),
    getInvoiceDetails: builder.mutation({
      query: ({ id }) => ({
        url: `/finance/invoice_management/get-invoice/${id}`,
        method: 'GET',
        headers: {
          Accept: 'application/json',
        },
      }),
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    setInvoiceArchive: builder.mutation({
      query: (id) => ({
        url: `/finance/invoice_management/set-archive/${id}`,
        method: 'POST',
        headers: {
          Accept: 'application/json',
        },
      }),
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    approvePayment: builder.mutation({
      query: ({ id, data }) => ({
        url: `/finance/invoice_management/approve/${id}`,
        method: 'POST',
        body: data,
        headers: {
          Accept: 'application/json',
        },
      }),
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    getProformaList: builder.query({
      query: (data) => `/finance/invoice_management/proforma_list?${encodeObjectToURIParams(data)}`,
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    searchTransactionIM: builder.query({
      query: ({ search, created_date }) => `/finance/invoice_management/transaction/list?search=${search}${created_date ? `&date=${created_date}` : ''}`,
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    setTransactionIM: builder.mutation({
      query: ({ id, data }) => ({
        url: `/finance/invoice_management/transaction/set/${id}`,
        method: 'POST',
        body: data,
        headers: {
          Accept: 'application/json',
        },
      }),
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    getExternalOrdersList: builder.query({
      query: (data) => `/finance/invoice_management/orders_list/external?${encodeObjectToURIParams(data)}`,
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    getInternalOrdersList: builder.query({
      query: (data) => `/finance/invoice_management/orders_list/internal?${encodeObjectToURIParams(data)}`,
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    invoiceListSearch: builder.query({
      query: ({ invoice_id }) => `/finance/invoice_management/group/list/${invoice_id}`,
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    setInvoiceGroup: builder.mutation({
      query: ({ data }) => ({
        url: '/finance/invoice_management/group/set',
        method: 'POST',
        body: data,
        headers: {
          Accept: 'application/json',
        },
      }),
      providesTags: (result, error) => useProvideTags(result, error),
    }),
    unsetInvoiceGroup: builder.mutation({
      query: ({ data, id }) => ({
        url: `/finance/invoice_management/group/unset/${id}`,
        method: 'POST',
        body: data,
        headers: {
          Accept: 'application/json',
        },
      }),
      providesTags: (result, error) => useProvideTags(result, error),
    }),
  }),
});

export const {
  useGetBoardDataQuery,
  useGetInvoiceDetailsMutation,
  useSetInvoiceArchiveMutation,
  useApprovePaymentMutation,
  useGetProformaListQuery,
  useSearchTransactionIMQuery,
  useSetTransactionIMMutation,
  useGetExternalOrdersListQuery,
  useGetInternalOrdersListQuery,
  useInvoiceListSearchQuery,
  useSetInvoiceGroupMutation,
  useUnsetInvoiceGroupMutation
} = sessionApi;

export default invoiceManagementSlice.reducer;
